import React, { useEffect, useRef, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Joi from "joi";
import useTranslator from "../../../hooks/useTranslator";
import FormFieldCaptcha from "../../../Components/Forms/FormFieldCaptcha";

// Define Joi validation schema
const schema = Joi.object({
  isOem: Joi.boolean().label("OEM Certified").required(),
});

// Form component
const IsOEM = ({ data, onUpdate, onBack }) => {

  const formikRef = useRef(null);
  const { _t } = useTranslator();

  const [captchaValue, setCaptchaValue] = useState(null);
  const [captchaError, setCaptchaError] = useState("");

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
    setCaptchaError("");
  };


  const initialValues = {
    isOem: data?.isOem !== undefined ? String(data.isOem) : null,
  };

  // Handle form submission
  const onSubmit = async (values) => {
    try {
      if (!captchaValue) {
        setCaptchaError(_t("Please complete the captcha."));
        return;
      }
      const { error } = schema.validate(values, { abortEarly: false });
      if (error) {
        throw new Error(error.details.map((err) => err.message).join(", "));
      }
      onUpdate({ isOem: values.isOem === "true" }, true);
    } catch (err) {
      console.error("Validation failed:", err.message);
    }
  };

  // Function to validate form data using Joi
  const validateForm = (values) => {
    onUpdate({ isOem: values.isOem === "true" });
    const { error } = schema.validate(values, { abortEarly: false });
    if (error) {
      const errors = {};
      error.details.forEach((err) => {
        errors[err.path[0]] = err.message;
      });
      return errors;
    }
    return {};
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={validateForm}
      onSubmit={onSubmit}
      innerRef={formikRef}
    >
      {({ values, handleChange, handleBlur, handleSubmit }) => (
        <Form onSubmit={handleSubmit} className="candidate-form accountform">
          <h4 className="mb-3">{_t("Is your Service Center OEM certified?")}</h4>

          <div className="row">

            <div className="col-md-12">
              <label className="selectbox w-100 h-auto" for={`isOem-yes`}>
                <Field
                  type="radio"
                  id={`isOem-yes`}
                  name="isOem"
                  value={"true"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <span className="checkmark"></span>
                <div className="primebox">
                  <p>{_t("Yes")}</p>
                </div>
              </label>
            </div>

            <div className="col-md-12">
              <label className="selectbox w-100 h-auto" for={`isOem-no`}>
                <Field
                  type="radio"
                  id={`isOem-no`}
                  name="isOem"
                  value={"false"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <span className="checkmark"></span>
                <div className="primebox">
                  <p>{_t("No")}</p>
                </div>
              </label>
            </div>

          </div>

          <div className="col-md-12">
            <ErrorMessage name="isOem" component="div" style={{ color: 'red' }} />
          </div>

          <div className="col-md-12 mb-5 center-captcha">
            <FormFieldCaptcha
              value={captchaValue}
              onChange={handleCaptchaChange}
              errorText={captchaError}
              showError={Boolean(captchaError)}
            />
          </div>

          <div className="row">
            <div className="col-md-12">
              <div class="formninputbar block text-center">
                <button type="button" onClick={onBack} className="btn btnwhite me-3">{_t("Back")}</button>
                <button type="submit" className="btn btnorange">{_t("Next")}</button>
              </div>
            </div>
          </div>

        </Form>
      )}
    </Formik>
  );
};

export default IsOEM;
