import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useTranslator from '../../hooks/useTranslator';
import { getCandidateDetails, saveCandidateLocationDetails } from '../../api-services/jobs';
import { Helmet } from 'react-helmet';
import toast from "react-hot-toast";
import Loader from '../../Components/Loader';

const CandidateLocationContinue = () => {

    const { id } = useParams();
    const { _t } = useTranslator();

    const [candidateDetails, setCandidateDetails] = useState(null);
    const navigate = useNavigate();

    const [saving, setSaving] = useState(false);
    const [resideInUs, setResideInUs] = useState(false);

    const totalSteps = 2;

    const [step, setStep] = useState(1);
    const [barWidth, setBarWidth] = useState(0);

    useEffect(() => {
        const completedSteps = step - 1;
        if (completedSteps > 0) {
            const percentage = (completedSteps / totalSteps) * 100;
            setBarWidth(percentage);
        } else {
            setBarWidth(0);
        }
    }, [step]);

    const changeStep = (step) => {
        setStep(step);
    }

    const handleBack = () => {
        changeStep(step - 1);
    }

    useEffect(() => {
        getCandidateDetails({ candidateId: id })
            .then((response) => {
                if (response) {
                    setCandidateDetails(response?.data?.candidate);
                }
            })
            .catch((error) => {
                navigate("/not-found");
            });
    }, [id]);

    const handleYesOnUsReside = () => {
        setResideInUs(true);
        changeStep(2);
    }

    const handleNoOnUsReside = () => {
        setResideInUs(false);
        changeStep(2);
    }

    const handleYesOnWorkVisaForUS = () => {
        handleSubmit(true);
    }

    const handleNoOnWorkVisaForUS = () => {
        handleSubmit(false);
    }

    const handleSubmit = (requiredUsVisa) => {
        const data = { resideInUs, requiredUsVisa };
        setSaving(true);
        saveCandidateLocationDetails({
            ...data,
            candidateId: candidateDetails?._id
        })
            .then((data) => {
                if (data.status === "success") {
                    toast.success(_t("Thanks you for details, we will get back to you."));
                    setSaving(false);
                    navigate("/");
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error(_t("Failed to complete your request, please contact support."));
                setSaving(false);
            });
    }

    return (
        <>
            {
                candidateDetails === null ? <>
                    <Helmet>
                        <meta name="robots" content="noindex, nofollow" />
                    </Helmet>
                </> : <>
                    <Helmet>
                        <title>Candidate: #{candidateDetails?._id ?? ""}</title>
                        <meta name="robots" content="noindex, nofollow" />
                    </Helmet>
                </>
            }

            <div className='JobDetail-block application-continue-block'>
                {
                    candidateDetails === null ? <>
                        <div className="my-5 empty-box">
                            <Loader />
                        </div>
                    </> : <>
                        <section className="applicationcontinue-section">
                            <div className="container">
                                <div className="quick-block" >
                                    <div className="primary-title">
                                        <h4>{_t("Location Review")}</h4>
                                        <h6><small>Reference ID: {candidateDetails?._id}</small></h6>
                                        <h5 className="px-5">{_t("thanksForApplyingCompleteForm")}</h5>
                                        <div className="bar-line" id="formapply"></div>
                                    </div>
                                    <div className="step-form-container step-1">
                                        <div className="container" id="formapply-1">
                                            {
                                                saving ? <>
                                                    <Loader />
                                                </> : <>

                                                    <div className="block-bar">
                                                        <div className="line">
                                                            <div className="lineinner" style={{ width: `${barWidth.toFixed(2)}%` }}></div>
                                                        </div>
                                                        <div className='w-full block mt-5'>
                                                            <div className="formsteps formstepsbig mt-3">
                                                                {
                                                                    step === 1 ? <>
                                                                        <h4 className="mb-5">{_t("Do you currently reside in the united states?")}</h4>
                                                                        <div className="formrow">
                                                                            <div className="dflex-inputbar form-100">
                                                                                <div className="btnbar twobtncenter">
                                                                                    <button className="btn btnwhite" onClick={handleNoOnUsReside} type="button">
                                                                                        {_t("No")}
                                                                                    </button>
                                                                                    <button className="btn btnorange" onClick={handleYesOnUsReside} type="button">
                                                                                        {_t("Yes")}
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </> : null
                                                                }
                                                                {
                                                                    step === 2 ? <>
                                                                        <h4 className="mb-5">{_t("Do you require a work visa sponsorship for us to work?")}</h4>
                                                                        <div className="formrow">
                                                                            <div className="dflex-inputbar form-100">
                                                                                <div className="btnbar twobtncenter">
                                                                                    <button className="btn btnwhite" onClick={handleYesOnWorkVisaForUS} type="button">
                                                                                        {_t("Yes")}
                                                                                    </button>
                                                                                    <button className="btn btnorange" onClick={handleNoOnWorkVisaForUS} type="button">
                                                                                        {_t("No")}
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </> : null
                                                                }
                                                                {
                                                                    (step > 1) ? <>
                                                                        <div className="formsteps-back">
                                                                            <a href={undefined} onClick={handleBack}>{_t("Go Back")}</a>
                                                                        </div>
                                                                    </> : null
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </>
                }
            </div >
        </>
    );

}

export default CandidateLocationContinue;
