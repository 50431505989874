import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import useTranslator from "../../hooks/useTranslator";

const JobsFilter = ({ label, value, setValues, reset, setReset, defaultValue = "" }) => {
  const { _t } = useTranslator();
  const [currentValue, setCurrentValue] = useState(defaultValue);

  useEffect(() => {
    if (reset) {
      setCurrentValue("");
      setReset(false);
    }
  }, [reset]);

  return (
    <>
      <Form.Select
        key={label}
        aria-label="Jobsexample"
        className="filter-dropdown"
        value={currentValue}
        onChange={(e) => {
          setValues(e.target.value);
          setCurrentValue(e.target.value);
        }}>
        <option value="">{_t(label)}</option>
        {value?.map((item) => (
          <option
            key={
              item.name === "Yes"
                ? true
                : item.name === "No"
                ? false
                : item.name
            }
            value={
              item.value === "Yes"
                ? true
                : item.value === "No"
                ? false
                : item.value
            }>
            {item.value}
          </option>
        ))}
      </Form.Select>
    </>
  );
};

export default JobsFilter;
