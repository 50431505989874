import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {
    servicesInterest: [],
    vehicles: [],
    providedServices: [],
    city: "",
  },
  error: null,
  next: false,
};

const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    saveData: (state, action) => {
      state.data = { ...state.data, ...action.payload };
    },
    clearData: (state, action) => {
      state.data = {};
    },
  },
});

export const { saveData, clearData } = formSlice.actions;

export default formSlice.reducer;
