import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import useTranslator from "../../hooks/useTranslator";
import PropTypes from "prop-types";

const AddModel = ({ eventType, value, show, hide, onSubmit }) => {
  const { _t } = useTranslator();
  const [name, setName] = useState(value ? value.name : "");

  const handleChange = (e) => {
    if (e) {
      setName(e.target.value);
    }
  };

  return (
    <>
      <Modal show={show} onHide={hide} centered className="darkmode-modal">
        <Modal.Header closeButton>
          <Modal.Title>
            {_t(`${eventType === "Create" ? "Add" : "Edit"} Job Category`)}
          </Modal.Title>
        </Modal.Header >
        <Modal.Body>
          <div className="userrole-detail-modal ">
            <Form.Control
              required
              type="text"
              onChange={handleChange}
              placeholder={_t("Job category")}
              defaultValue={name}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btnwhite" onClick={(e) => onSubmit({ name })}>
            {_t(eventType === "Create" ? "Create" : "Edit")}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

AddModel.propTypes = {
  eventType: PropTypes.string.isRequired,
  value: PropTypes.object,
  show: PropTypes.bool.isRequired,
  hide: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default AddModel;
