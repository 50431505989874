import React, { useEffect, useRef } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Joi from "joi";
import useTranslator from "../../../hooks/useTranslator";

// Define Joi validation schema
const schema = Joi.object({
  certifiedTechnicians: Joi.boolean().label("Certified Technicians").required(),
});

// Form component
const CertifiedTechnicians = ({ data, onUpdate, onBack }) => {

  const formikRef = useRef(null);
  const { _t } = useTranslator();

  const initialValues = {
    certifiedTechnicians: data?.certifiedTechnicians !== undefined ? String(data.certifiedTechnicians) : null,
  };

  // Handle form submission
  const onSubmit = async (values) => {
    try {
      const { error } = schema.validate(values, { abortEarly: false });
      if (error) {
        throw new Error(error.details.map((err) => err.message).join(", "));
      }
      onUpdate({ certifiedTechnicians: values.certifiedTechnicians === "true" }, true);
    } catch (err) {
      console.error("Validation failed:", err.message);
    }
  };

  // Function to validate form data using Joi
  const validateForm = (values) => {
    onUpdate({ certifiedTechnicians: values.certifiedTechnicians === "true" });
    const { error } = schema.validate(values, { abortEarly: false });
    if (error) {
      const errors = {};
      error.details.forEach((err) => {
        errors[err.path[0]] = err.message;
      });
      return errors;
    }
    return {};
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={validateForm}
      onSubmit={onSubmit}
      innerRef={formikRef}
    >
      {({ values, handleChange, handleBlur, handleSubmit }) => (
        <Form onSubmit={handleSubmit} className="candidate-form accountform">
          <h4 className="mb-3">{_t("Are your technicians certified?")}</h4>

          <div className="row">

            <div className="col-md-12">
              <label className="selectbox w-100 h-auto" for={`certifiedTechnicians-yes`}>
                <Field
                  type="radio"
                  id={`certifiedTechnicians-yes`}
                  name="certifiedTechnicians"
                  value={"true"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <span className="checkmark"></span>
                <div className="primebox">
                  <p>{_t("Yes")}</p>
                </div>
              </label>
            </div>

            <div className="col-md-12">
              <label className="selectbox w-100 h-auto" for={`certifiedTechnicians-no`}>
                <Field
                  type="radio"
                  id={`certifiedTechnicians-no`}
                  name="certifiedTechnicians"
                  value={"false"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <span className="checkmark"></span>
                <div className="primebox">
                  <p>{_t("No")}</p>
                </div>
              </label>
            </div>

          </div>

          <div className="col-md-12">
            <ErrorMessage name="certifiedTechnicians" component="div" style={{ color: 'red' }} />
          </div>

          <div className="row">
            <div className="col-md-12">
              <div class="formninputbar block text-center">
                <button type="button" onClick={onBack} className="btn btnwhite me-3">{_t("Back")}</button>
                <button type="submit" className="btn btnorange">{_t("Next")}</button>
              </div>
            </div>
          </div>

        </Form>
      )}
    </Formik>
  );
};

export default CertifiedTechnicians;
