import React, { useEffect, useRef, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Joi from "joi";
import useTranslator from "../../../hooks/useTranslator";
import FormFieldCaptcha from "../../../Components/Forms/FormFieldCaptcha";
import { Link } from "react-router-dom";
import { IoDocumentAttach } from "react-icons/io5";
import config from "../../../config";
import toast from "react-hot-toast";
import { uploadResume } from "../../../api-services/jobs";
import Loader from "../../../Components/Loader";

// Define Joi validation schema
const schema = Joi.object({
  resume: Joi.string().label("Resume").required(),
});

// Form component
const Resume = ({ data, onUpdate, onBack }) => {

  const formikRef = useRef(null);
  const { _t } = useTranslator();

  const [captchaValue, setCaptchaValue] = useState(null);
  const [captchaError, setCaptchaError] = useState("");

  const [uploading, setUploading] = useState(false);

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
    setCaptchaError("");
  };

  const initialValues = {
    resume: data?.resume ?? null,
  };

  console.log(initialValues)

  // Handle form submission
  const onSubmit = async (values) => {
    try {
      if (!captchaValue) {
        setCaptchaError(_t("Please complete the captcha."));
        return;
      }
      const { error } = schema.validate(values, { abortEarly: false });
      if (error) {
        throw new Error(error.details.map((err) => err.message).join(", "));
      }
      onUpdate(values, true);
    } catch (err) {
      console.error("Validation failed:", err.message);
    }
  };

  // Function to validate form data using Joi
  const validateForm = (values) => {
    onUpdate(values);
    const { error } = schema.validate(values, { abortEarly: false });
    if (error) {
      const errors = {};
      error.details.forEach((err) => {
        errors[err.path[0]] = err.message;
      });
      return errors;
    }
    return {};
  };


  const handleFileUpload = async (event, setFieldValue) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) return;

    setUploading(true);

    const formData = new FormData();
    formData.append('resume', selectedFile);

    uploadResume(formData).then((res) => {
      setUploading(false);
      const resume = res.data;
      setFieldValue('resume', resume);
    }).catch((err) => {
      setUploading(false);
      toast.error(err?.message);
    });

    event.target.value = '';
  }

  return (
    <Formik
      initialValues={initialValues}
      validate={validateForm}
      onSubmit={onSubmit}
      innerRef={formikRef}
    >
      {({ values, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
        <Form onSubmit={handleSubmit} className="candidate-form accountform">
          <h4 className="mb-3">{_t("Please upload your resume")}</h4>

          <div className="row">

            <div className="col-md-12">

              <div class="formninputbar">
                <label htmlFor="resume">{_t("Resume")}</label>
                {
                  uploading ? <>
                    <Loader text={_t("Uploading...")} />
                  </> : null
                }
                <input type="file" onChange={(e)=>handleFileUpload(e, setFieldValue)} id="resumeUpload" className={`form-control ${uploading ? 'hidden' : ''}`} />
                <Field
                  class="form-control"
                  type="hidden"
                  id="resume"
                  name="resume"
                  value={values.resume}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage name="resume" component="div" style={{ color: 'red' }} />
              </div>
              <div className="d-block mb-3">
                {values.resume ? (
                  <Link
                    className="form-help-link"
                    target="_blank"
                    to={`${config.server.imageBaseUrl}/${values.resume}`}>
                    <IoDocumentAttach /> {_t("View Resume")}
                  </Link>
                ) : null}
              </div>
            </div>

            <div className="col-md-12 mb-5 center-captcha">
              <FormFieldCaptcha
                value={captchaValue}
                onChange={handleCaptchaChange}
                errorText={captchaError}
                showError={Boolean(captchaError)}
              />
            </div>

          </div>

          <div className="row">
            <div className="col-md-12">
              <div class="formninputbar block text-center">
                <button type="button" onClick={onBack} className="btn btnwhite me-3">{_t("Back")}</button>
                <button type="submit" className="btn btnorange">{_t("Next")}</button>
              </div>
            </div>
          </div>

        </Form>
      )}
    </Formik>
  );
};

export default Resume;
