import React, { useEffect, useState } from "react";
import { Form, InputGroup, Table } from "react-bootstrap";
import { AiFillPlusCircle, AiOutlineSearch } from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import { LuSettings2 } from "react-icons/lu";
import { MdFilterList, MdOutlineMarkEmailUnread, MdLocationPin } from "react-icons/md";
import BackButton from "../../../Components/BackToPrev";
import Paginate from "../../../Components/Paginate";

import debounce from "lodash/debounce";
import { IoDocumentAttach } from "react-icons/io5";
import { Link, useSearchParams } from "react-router-dom";
import JobsFilter from "../../../Components/Filter/JobsFilter";
import config from "../../../config";
import {
  SelectTrueAndFalse,
  formatDateWithoutTime,
  urlEncode,
} from "../../../utils/helpers";

import Loader from "../../../Components/Loader";
import { imageBaseUrl } from "../../../api-services/auth";
import useTranslator from "../../../hooks/useTranslator";
import { useFetchJobCategory } from "../../../data-fetch/useJobCategory";
import { getCandidateMatches } from "../../../api-services/candidate";
import { useCandidateValues } from "../../../data-fetch/useCandidateValues";
import useStateAndCity from "../../../data-fetch/useStateAndCity";
import ApplicationEntries from "./ApplicationEntries";

const ApplicationsList = () => {

  let [searchParams, setSearchParams] = useSearchParams();

  const { _t } = useTranslator();

  const [loading, setLoading] = useState(false);

  const [applicationList, setApplicationList] = useState([]);
  const [totalDocs, setTotalDocs] = useState("");

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const [jobSearchText, setJobSearchText] = useState("");
  const [searchText, setSearchText] = useState("");
  const [jobState, setJobState] = useState("");
  const [jobCity, setJobCity] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [experience, setExperience] = useState("");
  const [certified, setCertified] = useState("");
  const [niche, setNiche] = useState(searchParams.get("jobCategory"));

  const [reset, setReset] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  const { jobCategories } = useFetchJobCategory();
  const { experiences, applicationStatuses } = useCandidateValues();

  const {
    fetchCities: fetchJobCities,
    stateList: jobStateList,
    cityList: jobCityList,
  } = useStateAndCity();

  const handleJobState = (val) => {
    setJobState(val);
    const filterState = jobStateList.find((st) => st.name === val);
    if (filterState) {
      fetchJobCities(filterState.isoCode);
    }
  };

  const {
    fetchCities: fetchCities,
    stateList: stateList,
    cityList: cityList,
  } = useStateAndCity();

  const handleState = (val) => {
    setState(val);
    const filterState = stateList.find((st) => st.name === val);
    if (filterState) {
      fetchCities(filterState.isoCode);
    }
  };

  const debouncedSearch = debounce((value) => {
    setLoading(true);
    getCandidateMatches({
      searchText,
      jobSearchText,
      page,
      limit,
      state,
      city,
      experience,
      certified,
      niche,
      jobState,
      jobCity
    })
      .then((data) => {
        if (data.status === "success") {
          setApplicationList(data.data.docs);
          setTotalDocs(data.data.totalDocs);
        }
      })
      .catch((error) => {
        console.log("error while fetch jobs admin", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, 500); // Adjust the debounce time as needed

  useEffect(() => {
    debouncedSearch(searchText);
  }, [
    searchText,
    jobSearchText,
    page,
    limit,
    state,
    city,
    experience,
    certified,
    niche,
    jobState,
    jobCity
  ]);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleJobSearch = (e) => {
    setJobSearchText(e.target.value);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  function resetFilterHandler() {
    setSearchText("");
    setJobSearchText("");
    setJobState("");
    setJobCity("");
    setState("");
    setCity("");
    setExperience("");
    setCertified("");
    setNiche("");
    setReset(true);
    debouncedSearch();
  }

  const filterHandler = () => {
    setShowFilter((prev) => !prev);
  };

  const nicheList = [];
  for (const jobCategory of jobCategories) {
    nicheList.push({
      name: jobCategory.value,
      value: jobCategory.value
    });
  }

  let jobStates = [];
  if (jobStateList && jobStateList.length > 0) {
    jobStates = jobStateList.map((jobItem) => { return { name: jobItem.name, value: jobItem.name } });
  }

  let jobCities = [];
  if (jobCityList && jobCityList.length > 0) {
    jobCities = jobCityList.map((cityItem) => { return { name: cityItem.name, value: cityItem.name } });
  }

  let states = [];
  if (stateList && stateList.length > 0) {
    states = stateList.map((jobItem) => { return { name: jobItem.name, value: jobItem.name } });
  }

  let cities = [];
  if (cityList && cityList.length > 0) {
    cities = cityList.map((cityItem) => { return { name: cityItem.name, value: cityItem.name } });
  }

  const experienceList = [];
  for (const experienceItem of experiences) {
    experienceList.push({
      name: experienceItem,
      value: experienceItem
    });
  }

  return (
    <>
      <div className="users-page">
        <div className="top-header">
          <div className="page-name title-with-backbtn mb-0">
            <BackButton />
            <h4>Job Applications</h4>
          </div>
        </div>
        {/* ----------searchbox---------- */}
        <div className="serachbar-box">
          <div className="searchbar">
            <input onChange={(e) => handleSearch(e)} placeholder="Search Candidate" class="theme-form-control" value={searchText} />
          </div>
          <div className="searchbar">
            <input onChange={(e) => handleJobSearch(e)} placeholder="Search Job" class="theme-form-control" value={jobSearchText} />
          </div>
        </div>
        {/* ----------------advance filter ----------------------- */}
        <div className="mb-4">
          <button className="btn btn-sm btnorange me-3" onClick={filterHandler}>
            <MdFilterList /> Advance Filter
          </button>
        </div>

        {showFilter ? (
          <>
            <div className="filter-item">
              <small>Job Filters</small>
              <div className="top-header filterbaradmin">
                <JobsFilter
                  label="State"
                  setValues={handleJobState}
                  value={jobStates}
                  reset={reset}
                  setReset={setReset}
                />
                <JobsFilter
                  label="City"
                  setValues={setJobCity}
                  value={jobCities}
                />
                <JobsFilter
                  label="Job Niche"
                  setValues={setNiche}
                  value={nicheList}
                  reset={reset}
                  setReset={setReset}
                  defaultValue={niche}
                />
              </div>
            </div>

            <div className="filter-item">
              <small>Candidate Filters</small>
              <div className="top-header filterbaradmin">
                <JobsFilter
                  label="State"
                  setValues={handleState}
                  value={states}
                  reset={reset}
                  setReset={setReset}
                />
                <JobsFilter
                  label="City"
                  setValues={setCity}
                  value={cities}
                />
                <JobsFilter
                  label="Experiences"
                  setValues={setExperience}
                  value={experienceList}
                  reset={reset}
                  setReset={setReset}
                />
                <JobsFilter
                  label="Certified"
                  setValues={setCertified}
                  value={SelectTrueAndFalse()}
                  reset={reset}
                  setReset={setReset}
                />

                <button
                  className="btn btnorange"
                  onClick={() => resetFilterHandler()}>
                  Reset
                </button>
              </div>
            </div>

          </>
        ) : null}

        <ApplicationEntries
          loading={loading}
          data={applicationList}
          totalDocs={totalDocs}
          page={page}
          applicationStatuses={applicationStatuses}
          handleChangePage={handleChangePage}
        />

      </div>
    </>
  );
};

export default ApplicationsList;
