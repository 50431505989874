import isArray from "lodash/isArray";
import moment from "moment";
import config from "../config";
import { QuickFormConstant } from "../constants/quick-form";
import { USERS_ROLES } from "../constants/user";

const validateEmail = (email) => {
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

const validatePhoneNo = (phone) => {
  var re = /^([0|\+[0-9]{1,5})?([6-9][0-9]{9})$/;
  return re.test(phone);
};

export const formattedDate = (date) => {
  const frmDate = date.split("T")[0];
  return frmDate;
};

const validatePassword = (password) => {
  var re =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{10,}/;
  return re.test(password);
};

const isSame = (str1, str2) => {
  return str1 === str2;
};

export const haveValue = (val) => {
  if (
    val !== "" &&
    val !== "undefined" &&
    val !== undefined &&
    val !== null &&
    val !== "null"
  ) {
    return true;
  } else {
    return false;
  }
};

export const urlEncode = (url) => {
  if (!url) {
    return url;
  }
  return url.toString().replace(/ /g, "-");
};

export const urlDecode = (url) => {
  if (!url) {
    return url;
  }
  return url.toString().replace(/-/g, " ");
};

export const capitalizedString = (myString) => {
  if (myString) {
    return myString.toString().charAt(0).toUpperCase() + myString.slice(1);
  }
  return myString;
};

const _serverError = (res) => {
  const ex = (res && res.body) || (res && JSON.stringify(res.text)) || {};
  if (ex.validation) {
    const keys = ex.validation.keys; // failed validations keys
    const errMap = {};

    // error message is contained in "[]" but in order the keys are, so split it up
    ex.message.match(/[^[\]]+(?=])/g).forEach(function (msg, idx) {
      errMap[keys[idx]] = isArray(msg) ? msg.join(". ") : msg;
    });

    ex.message = "Validation Error";
    ex.validation = errMap;
  }
  return ex;
};

const objectToFormData = function (obj, form, namespace) {
  let fd = form || new FormData();
  let formKey;

  for (let property in obj) {
    if (obj.hasOwnProperty(property)) {
      if (namespace) {
        formKey = namespace + "[" + property + "]";
      } else {
        formKey = property;
      }

      // Check if the property is an object, but not a File or Blob
      if (
        typeof obj[property] === "object" &&
        !(obj[property] instanceof Blob) &&
        !(obj[property] instanceof File) &&
        !(obj[property] instanceof Array)
      ) {
        objectToFormData(obj[property], fd, property);
      } else if (obj[property] instanceof Array) {
        // If it's an array

        for (let i = 0; i < obj[property].length; i++) {
          fd.append(formKey + "[]", obj[property][i]);
        }
      } else if (
        !(obj[property] instanceof Blob) &&
        !(obj[property] instanceof File)
      ) {
        // If it's a string or a non-File, non-Blob object
        fd.append(formKey, obj[property]);
      }
    }
  }
  return fd;
};

const intersperse = function (arr, sep) {
  if (arr.length === 0) {
    return [];
  }

  return arr.slice(1).reduce(
    function (xs, x, i) {
      return xs.concat([sep, " ", x]);
    },
    [arr[0]]
  );
};

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
const debounce = function (func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

let sortByKeys = (unordered) => {
  const ordered = {};
  Object.keys(unordered)
    .sort()
    .forEach(function (key) {
      ordered[key] = unordered[key];
    });
  return ordered;
};

const arrayContainsArray = (superset, subset) => {
  if (0 === subset.length) {
    return false;
  }
  return subset.every(function (value) {
    return superset.indexOf(value) >= 0;
  });
};

const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};

/**
 * Get the URL parameters
 * source: https://css-tricks.com/snippets/javascript/get-url-variables/
 * @param  {String} url The URL
 * @return {Object}     The URL parameters
 */
const getParams = (url, decodeURI = true) => {
  var params = {};
  var parser = document.createElement("a");
  parser.href = url;
  var query = parser.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split(/=(.+)/);
    params[pair[0]] = decodeURI ? decodeURIComponent(pair[1]) : pair[1];
  }
  return params;
};

const formatDateWithoutTime = (date) => {
  if (!date) {
    return date;
  }

  return moment(date).format("LL");
};

const validPinCode = (pinCode) => {
  let regxExp = /^\d{6}$/;
  return regxExp.test(pinCode);
};
const validPostiveHours = (hours) => {
  let regxExp = /^\d$/;
  return regxExp.test(hours);
};

const validPostiveInteger = (no) => {
  let regxExp = /^\d{1,5}$/;
  return regxExp.test(no);
};

const validPostiveMinutes = (minutes) => {
  let regxExp = /^\d{1,2}$/;
  return regxExp.test(minutes);
};

const validGstRate = (pinCode) => {
  // let regxExp =/\b[\+-]?[0-9]*[\.]?[0-9]+([eE][\+-]?[0-9]+)?\b/gm;
  let regxExp = /^(\d*\.)?\d+$/gm;

  return regxExp.test(pinCode);
};
const validlatitude = (latitude) => {
  let regxExp = /^-?([1-8]?[1-9]|[1-9]0)\.{1}\d{1,6}$/gm;
  return regxExp.test(latitude);
};

const validlongitude = (longitude) => {
  let regxExp = /^-?([1]?[1-7][1-9]|[1]?[1-8][0]|[1-9]?[0-9])\.{1}\d{1,6}$/gm;
  return regxExp.test(longitude);
};

const mobileCheck = function () {
  let check = false;
  if (/Mobi/.test(navigator.userAgent)) {
    check = true;
  }
  return check;
};

const converToLocalString = (number) => {
  return new Number(number).toLocaleString("en");
};

const chunkArray = (myArray, chunk_size) => {
  var index = 0;
  var arrayLength = myArray.length;
  var tempArray = [];

  for (index = 0; index < arrayLength; index += chunk_size) {
    let myChunk = myArray.slice(index, index + chunk_size);
    // Do something if you want with the group
    tempArray.push(myChunk);
  }

  return tempArray;
};

const getRandomNumberWithinRange = (maxNumber, length) => {
  var arr = [];
  while (arr.length < length) {
    var r = Math.floor(Math.random() * maxNumber) + 1;
    if (arr.indexOf(r) === -1) arr.push(r);
  }
  return arr;
};

function splitToChunks(array, parts) {
  let result = [];
  for (let i = parts; i > 0; i--) {
    result.push(array.splice(0, Math.ceil(array.length / i)));
  }
  return result;
}

export const withBackendUrl = (path, defaultPath = null) => {
  if (haveValue(path)) {
    path = path?.toString();
    if (path?.startsWith("http://") || path?.startsWith("https://")) {
      return path;
    } else {
      if (path.startsWith("/")) {
        path = path.substring(1);
      }
      return config.server.url + "/" + path;
    }
  } else {
    return "";
  }
};

export const isBusinessOwner = (role) => {
  if (role === "Shop Owner") {
    return true;
  }
  return false;
};

export const multiSelectOptions = (options) => {
  return options?.map((item) => ({ name: item }));
};

export const ExperienceConstantArray = () => {
  return [
    { value: QuickFormConstant.USER_EXPERIENCES.UNDER_5 },
    { value: QuickFormConstant.USER_EXPERIENCES.FIVE_PLUS },
    { value: QuickFormConstant.USER_EXPERIENCES.TEN_PLUS },
    { value: QuickFormConstant.USER_EXPERIENCES.FIFTEEN_PLUS },
  ];
};

export const CategoryConstantArray = () => {
  return [
    { value: USERS_ROLES.shop_owner },
    { value: USERS_ROLES.sole_proprietor },
  ];
};

export const SelectTrueAndFalse = () => {
  return [{ value: "Yes" }, { value: "No" }];
};

export const StateList = [
  {
    name: "Alabama",
    iso: "AL",
  },
  {
    name: "Alaska",
    iso: "AK",
  },
  {
    name: "American Samoa",
    iso: "AS",
  },
  {
    name: "Arizona",
    iso: "AZ",
  },
  {
    name: "Arkansas",
    iso: "AR",
  },
  {
    name: "California",
    iso: "CA",
  },
  {
    name: "Colorado",
    iso: "CO",
  },
  {
    name: "Connecticut",
    iso: "CT",
  },
  {
    name: "Delaware",
    iso: "DE",
  },
  {
    name: "District Of Columbia",
    iso: "DC",
  },
  {
    name: "Federated States Of Micronesia",
    iso: "FM",
  },
  {
    name: "Florida",
    iso: "FL",
  },
  {
    name: "Georgia",
    iso: "GA",
  },
  {
    name: "Guam",
    iso: "GU",
  },
  {
    name: "Hawaii",
    iso: "HI",
  },
  {
    name: "Idaho",
    iso: "ID",
  },
  {
    name: "Illinois",
    iso: "IL",
  },
  {
    name: "Indiana",
    iso: "IN",
  },
  {
    name: "Iowa",
    iso: "IA",
  },
  {
    name: "Kansas",
    iso: "KS",
  },
  {
    name: "Kentucky",
    iso: "KY",
  },
  {
    name: "Louisiana",
    iso: "LA",
  },
  {
    name: "Maine",
    iso: "ME",
  },
  {
    name: "Marshall Islands",
    iso: "MH",
  },
  {
    name: "Maryland",
    iso: "MD",
  },
  {
    name: "Massachusetts",
    iso: "MA",
  },
  {
    name: "Michigan",
    iso: "MI",
  },
  {
    name: "Minnesota",
    iso: "MN",
  },
  {
    name: "Mississippi",
    iso: "MS",
  },
  {
    name: "Missouri",
    iso: "MO",
  },
  {
    name: "Montana",
    iso: "MT",
  },
  {
    name: "Nebraska",
    iso: "NE",
  },
  {
    name: "Nevada",
    iso: "NV",
  },
  {
    name: "New Hampshire",
    iso: "NH",
  },
  {
    name: "New Jersey",
    iso: "NJ",
  },
  {
    name: "New Mexico",
    iso: "NM",
  },
  {
    name: "New York",
    iso: "NY",
  },
  {
    name: "North Carolina",
    iso: "NC",
  },
  {
    name: "North Dakota",
    iso: "ND",
  },
  {
    name: "Northern Mariana Islands",
    iso: "MP",
  },
  {
    name: "Ohio",
    iso: "OH",
  },
  {
    name: "Oklahoma",
    iso: "OK",
  },
  {
    name: "Oregon",
    iso: "OR",
  },
  {
    name: "Palau",
    iso: "PW",
  },
  {
    name: "Pennsylvania",
    iso: "PA",
  },
  {
    name: "Puerto Rico",
    iso: "PR",
  },
  {
    name: "Rhode Island",
    iso: "RI",
  },
  {
    name: "South Carolina",
    iso: "SC",
  },
  {
    name: "South Dakota",
    iso: "SD",
  },
  {
    name: "Tennessee",
    iso: "TN",
  },
  {
    name: "Texas",
    iso: "TX",
  },
  {
    name: "Utah",
    iso: "UT",
  },
  {
    name: "Vermont",
    iso: "VT",
  },
  {
    name: "Virgin Islands",
    iso: "VI",
  },
  {
    name: "Virginia",
    iso: "VA",
  },
  {
    name: "Washington",
    iso: "WA",
  },
  {
    name: "West Virginia",
    iso: "WV",
  },
  {
    name: "Wisconsin",
    iso: "WI",
  },
  {
    name: "Wyoming",
    iso: "WY",
  },
];

export const getUniqeStateAndCity = (array) => {
  const filterValue = array.reduce(
    (acc, lc) => {
      if (!acc.states.includes(lc.state)) {
        acc.states.push(lc.state);
      }
      if (!acc.cities.includes(lc.city)) {
        acc.cities.push(lc.city);
      }
      return acc;
    },
    { states: [], cities: [] }
  );
  return filterValue;
};

export const uniqueValueArray = (data, conditionCallback) => {
  const uniqueSet = data?.reduce((uniqueValuesSet, item) => {
    const conditionValue = conditionCallback(item);

    if (conditionValue && !uniqueValuesSet.has(conditionValue)) {
      uniqueValuesSet.add(conditionValue);
    }

    return uniqueValuesSet;
  }, new Set());

  // Convert the Set back to an array if needed
  const uniqueValuesArray = Array.from(uniqueSet).map((value) => ({ value }));
  return uniqueValuesArray;
};

export function getRandomBlogItems(array, count = 3) {
  if (array.length <= count) {
    return array;
  }
  const shuffledArray = array.slice();
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }

  return shuffledArray.slice(0, count);
}

export const selectedRole = () => {
  const value = localStorage.getItem("usrRole");

  if (value === "business_owner") {
    return "Shop Owner";
  } else if (value === "mechanic") {
    return "Mechanic";
  } else {
    return null;
  }
};

export const getSelectVehicles = (array, values) => {
  let valueExist = [];

  for (let item of array) {
    let name = removeSpecialCharacters(item.name);

    values.forEach((val) => {
      let valueIpdae = removeSpecialCharacters(val);
      if (name.includes(valueIpdae)) {
        valueExist.push(item.name);
      }
    });
  }
  return valueExist;
};

export function getDayDifferenceFromDate(providedDate) {
  if (!providedDate) {
    return providedDate;
  }
  const currentDate = moment(); // Define current date here
  const providedDateMoment = moment(providedDate);
  const differenceInDays = providedDateMoment.diff(currentDate, "days");

  return differenceInDays;
}

export const LocalLang = () => {
  const storedLang = window.location.href.split("/")[3];
  console.log("🚀 ~ LocalLang ~ storedLang:", storedLang);
  if (storedLang.includes("#")) {
    return storedLang.split("#")[0];
  }
  return storedLang;
};

export const updateLangUrl = (lang) => {
  console.log("🚀 ~ updateLangUrl ~ lang:", lang);
  const currentPath = window.location.pathname;
  const parts = currentPath.split("/");
  console.log("🚀 ~ updateLangUrl ~ parts:", parts);
  if (parts[1] !== lang) {
    parts[1] = lang;
    const newUrl = parts.join("/");
    // window.history.pushState({}, "", newUrl);
    console.log("new  url", newUrl);
    window.location.href = newUrl;
    // return newUrl;
  }
  return currentPath;
};

export function removeSpecialCharacters(str) {
  // Define a regular expression to match special characters
  const regex = /[^\w\s]/g;
  // Use replace method to remove special characters from the string
  return str.toString().toLowerCase().replace(regex, "");
}

export { _serverError as ServerError };

export { validateEmail };

export { objectToFormData };

export { intersperse };

export { debounce };

export { validatePassword };

export { sortByKeys };

export { isSame };

export { arrayContainsArray };

export { asyncForEach };

export { getParams };

export { formatDateWithoutTime };

export { validPinCode };

export { validGstRate };

export { validlatitude };

export { validlongitude };

export { validPostiveHours };

export { validPostiveMinutes };

export { validatePhoneNo };

export { validPostiveInteger };

export { mobileCheck };

export { converToLocalString };
export { chunkArray }; // Pass argment size of an array
export { getRandomNumberWithinRange };
export { splitToChunks }; // Pass argment no of array should create


const getServiceName = (serviceName) => {
  switch (serviceName) {
    case "Cars & Trucks":
      return "sedan.svg";
      break;
    case "Classic Cars":
      return "hatchback.svg";
      break;
    case "Motorcycles":
      return "sportbike.svg";
      break;
    case "Marine Vehicles":
      return "boat.svg";
      break;
    case "Small Engine Equipment":
      return "small-engine.svg";
      break;
    case "Heavy Machinery":
      return "jcb.svg";
      break;
    case "Commercial Trucks (Semi)":
      return "truck.svg";
      break;
    case "Commercial Buses":
      return "bus.svg";
      break;
    case "RVs":
      return "minitruck.svg";
      break;
  }
  return "sedan.svg";
}

const convertToBooleanValue = (value) => {
  if (haveValue(value)) {
    value = value.toString();
    if (value === "true") {
      return true;
    } else if (value === "false") {
      return false;
    }
  }
  return null
}

export { getServiceName, convertToBooleanValue };