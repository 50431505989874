import React from "react";
import { Spin } from "antd";

const Loader = ({ text = null }) => {
  return (
    <div className="loader">
      <Spin size="large" colorprimary="#ee612f" />
      {text ? <p>{text}</p> : null}
    </div>
  );
};

export default Loader;
