import React, { useEffect, useState } from "react";
import BackButton from "../../../Components/BackToPrev";
import CandidateForm from "./CandidateForm";

const CandidateNew = () => {

  return (
    <>
      <div className="users-page">
        <div className="top-header">
          <div className="page-name title-with-backbtn mb-0">
            <BackButton />
            <h4>New Candidate</h4>
          </div>
        </div>

        <div className="mt-5">
          <CandidateForm candidate={{}} />
        </div>

      </div>
    </>
  );
};

export default CandidateNew;
