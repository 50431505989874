import React, { useEffect, useRef, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Joi from "joi";
import config from "../../../config";
import { useCandidateValues } from "../../../data-fetch/useCandidateValues";
import useStateAndCity from "../../../data-fetch/useStateAndCity";
import { Link, useNavigate } from "react-router-dom";
import useTranslator from "../../../hooks/useTranslator";
import { IoDocumentAttach } from "react-icons/io5";
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import toast from "react-hot-toast";
import { updateCandidateDetails } from "../../../api-services/candidate";
import { convertToBooleanValue } from "../../../utils/helpers";

// Define Joi validation schema
const schema = Joi.object({
  firstName: Joi.string().required().label("First Name"),
  lastName: Joi.string().required().label("Last Name"),
  email: Joi.string().email({ tlds: false }).required().label("Email"),
  mobileNo: Joi.string().min(10).max(15).label("Mobile Number").allow(null).allow(""),
  gender: Joi.string().valid("male", "female", "other").label("Gender").allow(null).allow(""),
  state: Joi.string().label("State").allow(null).allow(""),
  city: Joi.string().label("City").allow(null).allow(""),
  zipcode: Joi.string().label("Zipcode").allow(null).allow(""),

  summary: Joi.string().allow(null).allow("").label("Summary").allow(null).allow(""),
  notes: Joi.string().allow(null).allow("").label("Notes").allow(null).allow(""),

  vehiclesServiced: Joi.array().items(Joi.string()).label("Vehicles Serviced"),
  servicesProvided: Joi.array().items(Joi.string()).label("Services Provided"),
  specialServices: Joi.array().items(Joi.string()).label("Special Services"),
  jobNiches: Joi.array().items(Joi.string()).label("Job Niches"),
  languages: Joi.array().items(Joi.string()).label("Languages"),

  resume: Joi.string().label("Resume").allow(null).allow(""),
  certificationText: Joi.string().allow(null).allow("").label("Certification Text").allow(null).allow(""),

  experienceYears: Joi.string().label("Experience Years").allow(null).allow(""),
  isCertified: Joi.boolean().label("Is Certified").allow(null).allow(""),

  businessName: Joi.string().allow(null).allow("").label("Business Name").allow(null).allow(""),
  numberOfTechnicians: Joi.number().label("Number of Technicians").allow(null).allow(""),
  certifiedTechnicians: Joi.boolean().label("Certified Technicians").allow(null).allow(""),
  isLicensed: Joi.boolean().label("Is Licensed").allow(null).allow(""),
  isOem: Joi.boolean().label("Is OEM").allow(null).allow(""),
});

// Form component
const CandidateForm = ({ candidate }) => {

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const formikRef = useRef(null);
  const { _t } = useTranslator();
  const { languages: languageData, experiences, vehiclesServiced, servicesProvided, specialServices } = useCandidateValues();

  const languagesOptions = languageData.map((item) => { return { value: item, label: item } });
  const vehiclesServicedOptions = vehiclesServiced.map((item) => { return { value: item, label: item } });
  const servicesProvidedOptions = servicesProvided.map((item) => { return { value: item, label: item } });
  const specialServicesOptions = specialServices.map((item) => { return { value: item, label: item } });

  const {
    fetchCities,
    stateList,
    cityList,
  } = useStateAndCity();

  const initialValues = {
    firstName: candidate?.firstName ?? "",
    lastName: candidate?.lastName ?? "",
    email: candidate?.email ?? "",
    mobileNo: candidate?.mobileNo ?? "",
    gender: candidate?.gender ?? "",
    state: candidate?.state ?? "",
    city: candidate?.city ?? "",
    zipcode: candidate?.zipcode ?? "",

    summary: candidate?.summary ?? "",
    notes: candidate?.notes ?? "",

    vehiclesServiced: candidate?.vehiclesServiced ?? [],
    servicesProvided: candidate?.servicesProvided ?? [],
    specialServices: candidate?.specialServices ?? [],
    jobNiches: candidate?.jobNiches ?? [],
    languages: candidate?.languages ?? [],

    resume: candidate?.resume ?? null,
    certificationText: candidate?.certificationText ?? "",

    experienceYears: candidate?.experienceYears ?? "",
    isCertified: convertToBooleanValue(candidate?.isCertified),

    businessName: candidate?.businessName ?? "",
    numberOfTechnicians: candidate?.numberOfTechnicians ?? null,
    certifiedTechnicians: candidate?.certifiedTechnicians !== undefined ? String(candidate.certifiedTechnicians) : null,
    isLicensed: candidate?.isLicensed !== undefined ? String(candidate.isLicensed) : null,
    isOem: candidate?.isOem !== undefined ? String(candidate.isOem) : null,
  };

  // Handle form submission
  const onSubmit = async (values) => {
    try {
      const { error } = schema.validate(values, { abortEarly: false });
      if (error) {
        throw new Error(error.details.map((err) => err.message).join(", "));
      }
      setLoading(true);
      updateCandidateDetails({
        _id: candidate?._id ?? null,
        ...values
      }).then((res) => {
        toast.success(_t("Candidate saved"));
        setLoading(false);
        if (!candidate?._id) {
          navigate(`/candidates`);
        }
      }).catch((err) => {
        toast.success(err?.message);
        setLoading(false);
      });
    } catch (err) {
      console.error("Validation failed:", err.message);
    }
  };

  // Function to validate form data using Joi
  const validateForm = (values) => {
    const { error } = schema.validate(values, { abortEarly: false });
    console.log(error);
    if (error) {
      const errors = {};
      error.details.forEach((err) => {
        errors[err.path[0]] = err.message;
      });
      return errors;
    }
    return {};
  };

  const handleState = (e) => {
    const selectedState = e.target.value;
    // Access Formik instance methods via ref
    if (formikRef.current) {
      const formikInstance = formikRef.current;
      formikInstance.handleChange(e);
      formikInstance.setFieldValue("city", "");
    }
    const filterState = stateList.find((st) => st.name === selectedState);
    if (filterState) {
      fetchCities(filterState.isoCode);
    }
  };

  useEffect(() => {
    if (candidate?.state && stateList && stateList.length > 0) {
      const filterState = stateList.find((st) => st.name === candidate?.state);
      if (filterState) {
        fetchCities(filterState.isoCode);
      }
    }
  }, [candidate, stateList])

  return (
    <Formik
      initialValues={initialValues}
      validate={validateForm}
      onSubmit={onSubmit}
      innerRef={formikRef}
    >
      {({ values, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
        <Form onSubmit={handleSubmit} className="candidate-form accountform">


          <div className="row">
            <div className="col-md-6">
              <div class="formninputbar">
                <label htmlFor="firstName">First Name</label>
                <Field
                  class="form-control"
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Enter First Name"
                />
                <ErrorMessage name="firstName" component="div" style={{ color: 'red' }} />
              </div>

            </div>
            <div className="col-md-6">

              <div class="formninputbar">
                <label htmlFor="lastName">Last Name</label>
                <Field
                  class="form-control"
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Enter Last Name"
                />
                <ErrorMessage name="lastName" component="div" style={{ color: 'red' }} />
              </div>

            </div>
            <div className="col-md-6">

              <div class="formninputbar">
                <label htmlFor="email">Email</label>
                <Field
                  class="form-control"
                  type="email"
                  id="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Enter Email"
                />
                <ErrorMessage name="email" component="div" style={{ color: 'red' }} />
              </div>

            </div>
            <div className="col-md-6">

              <div class="formninputbar">
                <label htmlFor="mobileNo">Mobile No</label>
                <Field
                  class="form-control"
                  type="text"
                  id="mobileNo"
                  name="mobileNo"
                  value={values.mobileNo}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Enter Mobile no."
                />
                <ErrorMessage name="mobileNo" component="div" style={{ color: 'red' }} />
              </div>

            </div>
            <div className="col-md-6">

              <div class="formninputbar">
                <label htmlFor="gender">Gender</label>
                <Field
                  class="form-control"
                  as="select"
                  id="gender"
                  name="gender"
                  value={values.gender}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Select Gender"
                >
                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </Field>
                <ErrorMessage name="gender" component="div" style={{ color: 'red' }} />
              </div>

            </div>

            <div className="col-md-6">

              <div class="formninputbar">
                <label htmlFor="state">State</label>
                <Field
                  class="form-control"
                  type="text"
                  id="state"
                  name="state"
                  value={values.state}
                  onChange={handleState}
                  onBlur={handleBlur}
                  as="select"
                >
                  <option value={""}>Select State</option>
                  {
                    stateList && stateList.map((state) => {
                      return <>
                        <option value={state.name}>{state.name}</option>
                      </>
                    })
                  }
                </Field>
                <ErrorMessage name="state" component="div" style={{ color: 'red' }} />
              </div>

            </div>
            <div className="col-md-6">

              <div class="formninputbar">
                <label htmlFor="city">City</label>
                <Field
                  class="form-control"
                  type="text"
                  id="city"
                  name="city"
                  value={values.city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  as="select"
                >
                  <option value={""}>Select City</option>
                  {
                    cityList && cityList.map((city) => {
                      return <>
                        <option value={city.name}>{city.name}</option>
                      </>
                    })
                  }
                </Field>
                <ErrorMessage name="city" component="div" style={{ color: 'red' }} />
              </div>

            </div>
            <div className="col-md-6">

              <div class="formninputbar">
                <label htmlFor="zipcode">Zipcode</label>
                <Field
                  class="form-control"
                  type="text"
                  id="zipcode"
                  name="zipcode"
                  value={values.zipcode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Enter Zipcode"
                />
                <ErrorMessage name="zipcode" component="div" style={{ color: 'red' }} />
              </div>

            </div>
            <div className="col-md-6">

              <div class="formninputbar">
                <label htmlFor="isCertified">Is Certified</label>
                <Field
                  class="form-control"
                  type="checkbox"
                  id="isCertified"
                  name="isCertified"
                  checked={values.isCertified}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  as="select"
                >
                  <option value={""}>Select</option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </Field>
                <ErrorMessage name="isCertified" component="div" style={{ color: 'red' }} />
              </div>

            </div>

            <div className="col-md-6">

              <div class="formninputbar">
                <label htmlFor="experienceYears">Experience</label>
                <Field
                  class="form-control"
                  type="checkbox"
                  id="experienceYears"
                  name="experienceYears"
                  checked={values.experienceYears}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  as="select"
                >
                  <option value={""}>Select</option>
                  {
                    experiences.map((experience) => {
                      return <>
                        <option value={experience}>{experience}</option>
                      </>
                    })
                  }
                </Field>
                <ErrorMessage name="experienceYears" component="div" style={{ color: 'red' }} />
              </div>

            </div>

            <div className="col-md-6">

              <div class="formninputbar">
                <label htmlFor="vehiclesServiced">Vehicles Serviced</label>
                <Select
                  isMulti
                  id="vehiclesServiced"
                  name="vehiclesServiced"
                  options={vehiclesServicedOptions}
                  value={values.vehiclesServiced.map((item) => { return { label: item, value: item } })}
                  onChange={(selectedOptions) => {
                    setFieldValue("vehiclesServiced", selectedOptions.map((selectedOption) => selectedOption.value));
                  }}
                  className="react-select-container form-control"
                  classNamePrefix="react-select"
                />
                <ErrorMessage name="vehiclesServiced" component="div" style={{ color: 'red' }} />
              </div>

            </div>

            <div className="col-md-6">

              <div class="formninputbar">
                <label htmlFor="servicesProvided">Services Provided</label>
                <Select
                  isMulti
                  id="servicesProvided"
                  name="servicesProvided"
                  options={servicesProvidedOptions}
                  value={values.servicesProvided.map((item) => { return { label: item, value: item } })}
                  onChange={(selectedOptions) => {
                    setFieldValue("servicesProvided", selectedOptions.map((selectedOption) => selectedOption.value));
                  }}
                  className="react-select-container form-control"
                  classNamePrefix="react-select"
                />
                <ErrorMessage name="servicesProvided" component="div" style={{ color: 'red' }} />
              </div>

            </div>

            <div className="col-md-6">

              <div class="formninputbar">
                <label htmlFor="specialServices">Special Services</label>
                <Select
                  isMulti
                  id="specialServices"
                  name="specialServices"
                  options={specialServicesOptions}
                  value={values.specialServices.map((item) => { return { label: item, value: item } })}
                  onChange={(selectedOptions) => {
                    setFieldValue("specialServices", selectedOptions.map((selectedOption) => selectedOption.value));
                  }}
                  className="react-select-container form-control"
                  classNamePrefix="react-select"
                />
                <ErrorMessage name="specialServices" component="div" style={{ color: 'red' }} />
              </div>

            </div>

            <div className="col-md-6">

              <div class="formninputbar">
                <label htmlFor="languages">Languages</label>
                <CreatableSelect
                  isMulti
                  id="languages"
                  name="languages"
                  options={languagesOptions}
                  value={values.languages.map((item) => { return { label: item, value: item } })}
                  onChange={(selectedOptions) => {
                    setFieldValue("languages", selectedOptions.map((selectedOption) => selectedOption.value));
                  }}
                  className="react-select-container form-control"
                  classNamePrefix="react-select"
                />
                <ErrorMessage name="languages" component="div" style={{ color: 'red' }} />
              </div>

            </div>

            <div className="col-md-6">

              <div class="formninputbar">
                <label htmlFor="jobNiches">Job Niches (Based on jobs applied, not editable)</label>
                <CreatableSelect
                  isMulti
                  id="jobNiches"
                  name="jobNiches"
                  isDisabled={true}
                  options={languagesOptions}
                  value={values.jobNiches.map((item) => { return { label: item, value: item } })}
                  onChange={(selectedOptions) => {
                    setFieldValue("jobNiches", selectedOptions.map((selectedOption) => selectedOption.value));
                  }}
                  className="react-select-container form-control"
                  classNamePrefix="react-select"
                />
                <ErrorMessage name="jobNiches" component="div" style={{ color: 'red' }} />
              </div>

            </div>

            <div className="col-md-6">

              <div class="formninputbar">
                <label htmlFor="resume">Resume</label>
                <input type="file" id="resumeUpload" className="form-control" />
                <Field
                  class="form-control"
                  type="hidden"
                  id="resume"
                  name="resume"
                  value={values.resume}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage name="resume" component="div" style={{ color: 'red' }} />
              </div>
              <div className="d-block mb-3">
                {candidate?.resume ? (
                  <Link
                    className="form-help-link"
                    target="_blank"
                    to={`${config.server.imageBaseUrl}/${candidate?.resume}`}>
                    <IoDocumentAttach /> {_t("View Resume")}
                  </Link>
                ) : null}
              </div>
            </div>


          </div>

          <hr />

          <h5 className="my-4">Business Info</h5>

          <div className="row">
            <div className="col-md-6">
              <div class="formninputbar">
                <label htmlFor="businessName">Business Name</label>
                <Field
                  class="form-control"
                  type="text"
                  id="businessName"
                  name="businessName"
                  value={values.businessName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Enter Business Name"
                />
                <ErrorMessage name="businessName" component="div" style={{ color: 'red' }} />
              </div>
            </div>
            <div className="col-md-6">
              <div class="formninputbar">
                <label htmlFor="numberOfTechnicians">No. of Technician</label>
                <Field
                  class="form-control"
                  type="number"
                  id="numberOfTechnicians"
                  name="numberOfTechnicians"
                  value={values.numberOfTechnicians}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Enter No. of Technician"
                />
                <ErrorMessage name="numberOfTechnicians" component="div" style={{ color: 'red' }} />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div class="formninputbar">
                <label htmlFor="certifiedTechnicians">Certified Technicians</label>
                <Field
                  class="form-control"
                  type="checkbox"
                  id="certifiedTechnicians"
                  name="certifiedTechnicians"
                  checked={values.certifiedTechnicians}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  as="select"
                >
                  <option value={""}>Select</option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </Field>
                <ErrorMessage name="certifiedTechnicians" component="div" style={{ color: 'red' }} />
              </div>
            </div>
            <div className="col-md-4">
              <div class="formninputbar">
                <label htmlFor="isLicensed">Licensed and Insured</label>
                <Field
                  class="form-control"
                  type="checkbox"
                  id="isLicensed"
                  name="isLicensed"
                  checked={values.isLicensed}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  as="select"
                >
                  <option value={""}>Select</option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </Field>
                <ErrorMessage name="isLicensed" component="div" style={{ color: 'red' }} />
              </div>
            </div>
            <div className="col-md-4">
              <div class="formninputbar">
                <label htmlFor="isOem">OEM Certified Business</label>
                <Field
                  class="form-control"
                  type="checkbox"
                  id="isOem"
                  name="isOem"
                  checked={values.isOem}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  as="select"
                >
                  <option value={""}>Select</option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </Field>
                <ErrorMessage name="isOem" component="div" style={{ color: 'red' }} />
              </div>
            </div>
          </div>

          <hr />

          <h5 className="my-4">Additional Info</h5>

          <div className="row">
            <div className="col-md-12">
              <div class="formninputbar">
                <label htmlFor="certificationText">Certification Text</label>
                <Field
                  class="form-control"
                  type="text"
                  id="certificationText"
                  name="certificationText"
                  value={values.certificationText}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage name="certificationText" component="div" style={{ color: 'red' }} />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div class="formninputbar">
                <label htmlFor="summary">Summary</label>
                <Field
                  class="form-control"
                  type="text"
                  id="summary"
                  name="summary"
                  value={values.summary}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  as="textarea"
                  rows="10"
                />
                <ErrorMessage name="summary" component="div" style={{ color: 'red' }} />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div class="formninputbar">
                <label htmlFor="notes">Notes</label>
                <Field
                  class="form-control"
                  type="text"
                  id="notes"
                  name="notes"
                  value={values.notes}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  as="textarea"
                  rows="10"
                />
                <ErrorMessage name="notes" component="div" style={{ color: 'red' }} />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div class="formninputbar">
                <button disabled={loading} type="submit" className="btn btnorange btn btn-primary">{loading ? "Please wait..." : "Save"}</button>
              </div>
            </div>
          </div>

        </Form>
      )}
    </Formik>
  );
};

export default CandidateForm;
