const Routes = {
  auth: {
    logout: "auth/logout",
    adminRegister: "auth/admin/register",
    resetPassword: "auth/admin/reset-password",
    currentUser: "auth/current-user",
    loginUser: "auth/login/user",
  },

  users: {
    userList: "users",
    accessControl: "users/access-control",
    userInfo: "users/user-info",
    editUser: "users/edit-user",
    updatePassword: "users/update-password",
  },

  job: {
    jobs: "jobs",
    usrJobs: "jobs/user",
    userListing: "jobs/users",
    jobListing: "jobs/jobList",
    jobEdit: "jobs/job-edit",
    jobDeleted: "jobs/job-delete",
    jobRepublish: "jobs/job-republish",
    applicationDetails: "jobs/application-details",
    candidateDetails: "jobs/candidate-details",
    saveCandidateLocationDetails: "jobs/save-candidate-location-details",
    saveCandidateQualificationDetails: "jobs/save-candidate-qualification-details",
    apply: "jobs/apply-to-job",
    uploadResume: "jobs/upload-resume",
    getCandidateByEmail: "jobs/candidate-by-email"
  },
  applicant: {
    GetALL: "applications",
    jobApply: "applications",
    ApplicationByJobId: "applications/jobs",
    ApplicationStatusUpdate: "applications/status/update",
    applicationProfile: "applications/application-profile",
    editProfile: "applications/application-profile-edit",
    exportSheetData: "applications/export-applications",
    sendMailOutReach: "applications/send-mail/outreach",
  },
  candidates: {
    list: "candidates",
    view: "candidates/view",
    save: "candidates/save",
    values: "candidates/values",
    matches: "candidates/matches",
    updateMatchStage: "candidates/match/update",
  },
  leads: {
    adminLeads: "leads/admin",
    covertLeads: "leads/covert-to-lead",
  },
  jobCategory: {
    create: "jobCategories",
    update: "jobCategories/update",
    delete: "jobCategories/delete",
    getAll: "jobCategories",
    selectAll: "jobCategories/selectAll",
  },

  shortApplication: {
    create: "shortApplications",
    get: "shortApplications",
  },

  contactForms: {
    crate: "contacts/create",
    getList: "contacts",
  },

  dashboardRoutes: {
    categoryGroup: "dashboard/category-group",
    getAdminSetting: "dashboard/admin-setting",
    updateSetting: "dashboard/update-setting",
  },
  blogs: {
    allblogs: "blogs/admin",
    updateblog: "blogs/update",
    getBlogById: "blogs/blog-by-id",
    create: "blogs/create",
    delete: "blogs/isarchived",
    updateCoverImage: "blogs/updateCoverImage",
    categoryBlogs: "blogs/category",
    categoriesList: "blogs/categories",
    isPrimary: "blogs/isPrimary",
  },
  siteMap: {
    get: "/sitemap",
  },
  langs: {
    get: "lang",
    updateEnglish: "lang/update/english",
    updateSpanish: "lang/update/spanish",
  },
  emails: {
    get: "emails",
    post: "emails",
  },
  emailTemplates: {
    list: "email-templates/list",
    save: "email-templates/save",
  },
  helper: {
    states: "states",
    cities: "cities",
  },
};

export default Routes;
