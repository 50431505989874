import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { CgProfile } from "react-icons/cg";
import Paginate from "../../../Components/Paginate";
import { IoDocumentAttach } from "react-icons/io5";
import { Link } from "react-router-dom";
import config from "../../../config";
import toast from "react-hot-toast";
import {
  formatDateWithoutTime,
  urlEncode,
} from "../../../utils/helpers";

import Loader from "../../../Components/Loader";
import useTranslator from "../../../hooks/useTranslator";
import { updateMatchStage } from "../../../api-services/candidate";

const ApplicationEntries = ({ data, loading, totalDocs, page, handleChangePage, applicationStatuses = [], allCandidates = true }) => {

  const { _t } = useTranslator();

  const [stageValues, setStageValues] = useState(
    data.reduce((acc, application) => {
      acc[application._id] = application.status || "";
      return acc;
    }, {})
  );

  useEffect(() => {
    const stageThings = data.reduce((acc, application) => {
      acc[application._id] = application.status || "";
      return acc;
    }, {});
    setStageValues(stageThings);
  }, [data]);

  const handleStageChange = (applicationId, value) => {
    setStageValues((prev) => ({
      ...prev,
      [applicationId]: value,
    }));
  };

  const handleFormSubmit = (event, applicationId) => {
    event.preventDefault();
    event.stopPropagation();
    const stage = stageValues[applicationId];
    updateMatchStage({
      stage,
      applicationId
    }).then((res) => {
      toast.success("Application stage has been updated");
    }).catch((err) => {
      toast.success(`Failed to update application stage ${err?.message}`);
    });
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="users-table">
            <Table responsive>
              <thead>
                <tr>
                  {
                    allCandidates ? <>
                      <th>{_t("First Name")}</th>
                      <th>{_t("Last Name")}</th>
                      <th>{_t("Email")}</th>
                      <th>{_t("Phone")}</th>
                      <th>{_t("Location")}</th>
                    </> : null
                  }
                  <th>{_t("Job")}</th>
                  <th>{_t("Job Category")}</th>
                  <th>{_t("Stage")}</th>
                  <th>{_t("Job Niche")}</th>
                  <th>{_t("Job Location")}</th>
                  <th>{_t("Applied On")}</th>
                  <th>{_t("Created On")}</th>
                  {
                    allCandidates ? <>
                      <th>{_t("Experience")}</th>
                      <th>{_t("Certified")}</th>
                      <th>{_t("Resume")}</th>
                      <th>{_t("Action")}</th>
                    </> : null
                  }
                </tr>
              </thead>
              <tbody>
                {data?.map((application, index) => (
                  <tr key={index}>
                    {
                      allCandidates ? <>
                        <td>
                          <div className="user-details">
                            <div>
                              <p className="user-name">{application?.candidateDetails?.firstName}</p>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="user-details">
                            <div>
                              <p className="user-name">{application?.candidateDetails?.lastName}</p>
                            </div>
                          </div>
                        </td>
                        <td>{application?.candidateDetails?.email}</td>
                        <td className="red-text">
                          {application?.candidateDetails?.mobileNo
                            ? application?.candidateDetails?.mobileNo
                            : "N/A"}
                        </td>
                        <td className="red-text">
                          {application?.candidateDetails?.city}
                          {application?.candidateDetails?.state ? `, ${application?.candidateDetails?.state}` : ''}
                          {application?.candidateDetails?.country ? `, ${application?.candidateDetails?.country}` : ''}
                        </td>
                      </> : null
                    }
                    <td className="red-text">
                      <Link to={`/job/edit/${application?.jobId}`}>{application?.jobDetails?.jobTitle}</Link>
                    </td>
                    <td className="red-text">
                      {application?.jobDetails?.category}
                    </td>
                    <td className="red-text">
                      <form onSubmit={(event) => handleFormSubmit(event, application._id)} className="table-actions">
                        <select
                          name="stage"
                          className="form-control table-select-status"
                          value={stageValues[application._id]}
                          onChange={(e) => handleStageChange(application._id, e.target.value)}
                        >
                          {applicationStatuses.map((status) => (
                            <option value={status} key={status}>
                              {status}
                            </option>
                          ))}
                        </select>
                        <button className="badge badge-primary" type="submit">
                          Update
                        </button>
                      </form>
                    </td>

                    <td className="red-text">
                      {application?.jobDetails?.jobCategory}
                    </td>
                    <td className="red-text">
                      {application?.jobDetails?.city}
                      {application?.jobDetails?.state ? `, ${application?.jobDetails?.state}` : ''}
                      {application?.jobDetails?.country ? `, ${application?.jobDetails?.country}` : ''}
                    </td>
                    <td className="red-text">
                      {formatDateWithoutTime(application?.submittedAt)}
                    </td>
                    <td className="red-text">
                      {formatDateWithoutTime(application?.createdAt)}
                    </td>
                    {
                      allCandidates ? <>
                        <td className="red-text">
                          {application?.candidateDetails?.experienceYears}
                        </td>
                        <td className="red-text">
                          {application?.candidateDetails?.isCertified ? "Yes" : "No"}
                        </td>

                        <td className="red-text">
                          {application?.candidateDetails?.resume ? (
                            <Link
                              target="_blank"
                              to={`${config.server.imageBaseUrl}/${application?.candidateDetails?.resume}`}>
                              <IoDocumentAttach color="black" /> {_t("Resume")}
                            </Link>
                          ) : (
                            "N/A"
                          )}
                        </td>
                        <td>
                          <Link to={`/candidates/${application?.candidateDetails?._id}`}>
                            <td className="red-text">
                              <button>
                                <CgProfile className="ml-2" />
                                {_t("View Profile")}
                              </button>
                            </td>
                          </Link>
                        </td>
                      </> : null
                    }
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <Paginate
              total={totalDocs}
              onChange={handleChangePage}
              page={page}
            />
          </div>
        </>
      )}
    </>
  );
};

export default ApplicationEntries;
