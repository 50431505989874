import React, { useEffect, useState } from "react";
import { Form, InputGroup, Table } from "react-bootstrap";
import { AiFillPlusCircle, AiOutlineSearch } from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import { LuSettings2 } from "react-icons/lu";
import { MdFilterList, MdOutlineMarkEmailUnread, MdLocationPin } from "react-icons/md";
import BackButton from "../../../Components/BackToPrev";
import Paginate from "../../../Components/Paginate";

import {
  exportSheetDataApplication,
  getApplications,
} from "../../../api-services/application";

import debounce from "lodash/debounce";
import { IoDocumentAttach } from "react-icons/io5";
import { Link, useSearchParams } from "react-router-dom";
import JobsFilter from "../../../Components/Filter/JobsFilter";
import config from "../../../config";
import { ApplicationSTATUS } from "../../../constants/applicant";
import {
  CategoryConstantArray,
  ExperienceConstantArray,
  SelectTrueAndFalse,
  formatDateWithoutTime,
  uniqueValueArray,
} from "../../../utils/helpers";

import Loader from "../../../Components/Loader";
import { imageBaseUrl } from "../../../api-services/auth";
import useTranslator from "../../../hooks/useTranslator";
import { useFetchJobCategory } from "../../../data-fetch/useJobCategory";

const Applications = () => {

  let [searchParams, setSearchParams] = useSearchParams();

  const { _t } = useTranslator();
  const [applicationList, setApplicationList] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [status, setStatus] = useState("");
  const [totalDocs, setTotalDocs] = useState("");
  const [selectState, setSelectState] = useState("");
  const [city, setSelectCity] = useState("");
  const [experiencesSelected, setExperiencesSelected] = useState("");
  const [certifiedSelect, setCertifiedSelect] = useState(null);
  const [category, setCategorySelect] = useState(null);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [reset, setReset] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [jobNiche, setJobNiche] = useState(null);
  const [jobCategory, setJobCategory] = useState(searchParams.get("jobCategory"));
  const [jobNicheList, setJobNicheList] = useState(null);

  const [showArchived, setShowArchived] = useState(false);

  const { jobCategories } = useFetchJobCategory();

  const debouncedSearch = debounce((value) => {
    setLoading(true);
    getApplications({
      page,
      limit,
      status,
      searchText,
      selectState,
      city,
      experiencesSelected,
      certifiedSelect,
      category,
      jobNiche,
      jobCategory,
      showArchived
    })
      .then((data) => {
        if (data.status === "success") {
          setApplicationList(data.data.docs);
          const nicheList = uniqueValueArray(
            data.data.docs,
            (item) => item.jobId.jobCategory
          );

          const states = uniqueValueArray(data.data.docs, (item) => item.state);
          const cities = uniqueValueArray(data.data.docs, (item) => item.city);
          setJobNicheList(nicheList);
          setStateList(states);
          setCityList(cities);
          setTotalDocs(data.data.totalDocs);
        }
      })
      .catch((error) => {
        console.log("error while fetch jobs admin", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, 500); // Adjust the debounce time as needed

  useEffect(() => {
    debouncedSearch(searchText);
  }, [
    searchText,
    page,
    limit,
    searchText,
    status,
    selectState,
    city,
    experiencesSelected,
    certifiedSelect,
    category,
    jobNiche,
    jobCategory,
    showArchived
  ]);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  function resetFilterHandler() {
    setSearchText("");
    setStatus("");
    setSelectCity("");
    setSelectState("");
    setExperiencesSelected("");
    setCertifiedSelect("");
    setCategorySelect("");
    setReset(true);
    setJobNiche("");
    debouncedSearch();
  }

  function exportSheetData() {
    exportSheetDataApplication()
      .then((res) => {
        const fileUrl = `${imageBaseUrl}/exportedData.xlsx`;

        // Create a temporary anchor element
        const a = document.createElement("a");
        a.href = fileUrl;
        a.target = "_blank";
        a.download = "exportedData.xlsx";

        // Trigger a click event to start the download
        a.click();
      })
      .catch((err) => {
        console.log("err while ", err);
      });
  }

  const filterHandler = () => {
    setShowFilter((prev) => !prev);
  };

  const jobCategoryLists = [];
  for (const jobCategory of jobCategories) {
    jobCategoryLists.push({
      name: jobCategory.value,
      value: jobCategory.value
    });
  }

  const viewArchived = () => {
    setShowArchived(!showArchived);
  }

  return (
    <>
      <div className="users-page">
        <div className="top-header">
          <div className="page-name title-with-backbtn mb-0">
            <BackButton />
            <h4>Applications</h4>
          </div>
          <div className="import-export">
            {
              !showArchived ? <>
                <Link className="btn btnorange" to="/applicant/add">
                  <AiFillPlusCircle /> Create Applicant Profile
                </Link>
                <button className="btn btnorange" onClick={exportSheetData}>
                  Export
                </button>
              </> : null
            }
          </div>
        </div>
        {/* ----------searchbox---------- */}
        <div className="serachbar-box">
          <div className="searchbar">
            <InputGroup>
              <InputGroup.Text id="basic-addon1">
                <AiOutlineSearch />
              </InputGroup.Text>
              <Form.Control
                placeholder="Search Customers"
                aria-label="Username"
                aria-describedby="basic-addon1"
                value={searchText}
                onChange={(e) => handleSearch(e)}
              />
            </InputGroup>
          </div>
          <div className="filter">
            <LuSettings2 size={30} />
            <select value={status} onChange={(e) => setStatus(e.target.value)}>
              <option value="">All</option>
              <option value={ApplicationSTATUS.APPLIED}>
                {ApplicationSTATUS.APPLIED}
              </option>
              <option value={ApplicationSTATUS.APPROVED}>
                {ApplicationSTATUS.APPROVED}
              </option>
              <option value={ApplicationSTATUS.CONTACTED}>
                {ApplicationSTATUS.CONTACTED}
              </option>
              <option value={ApplicationSTATUS.INTERESTED}>
                {ApplicationSTATUS.INTERESTED}
              </option>
              <option value={ApplicationSTATUS.NOT_INTERESTED}>
                {ApplicationSTATUS.NOT_INTERESTED}
              </option>
              <option value={ApplicationSTATUS.ONBOARDED}>
                {ApplicationSTATUS.ONBOARDED}
              </option>
              <option value={ApplicationSTATUS.REJECTED}>
                {ApplicationSTATUS.REJECTED}
              </option>
            </select>
          </div>
        </div>
        {/* ----------------advance filter ----------------------- */}
        <div className="mb-5">
          <button className="btn btn-sm btnorange me-3" onClick={filterHandler}>
            <MdFilterList /> Advance Filter
          </button>
          {
            showArchived ? <>
              <button className="btn btn-sm btnorange" onClick={viewArchived}>
                View Un-archived
              </button>
            </> : <>
              <button className="btn btn-sm btnorange" onClick={viewArchived}>
                View Archived
              </button>
            </>
          }
        </div>

        {showFilter ? (
          <div className="top-header filterbaradmin">
            <JobsFilter
              label="State"
              setValues={setSelectState}
              value={stateList}
              reset={reset}
              setReset={setReset}
            />
            <JobsFilter
              label="City"
              setValues={setSelectCity}
              value={cityList}
            />
            <JobsFilter
              label="Experiences"
              setValues={setExperiencesSelected}
              value={ExperienceConstantArray()}
              reset={reset}
              setReset={setReset}
            />
            <JobsFilter
              label="Category"
              setValues={setCategorySelect}
              value={CategoryConstantArray()}
              reset={reset}
              setReset={setReset}
            />
            <JobsFilter
              label="Certified"
              setValues={setCertifiedSelect}
              value={SelectTrueAndFalse()}
              reset={reset}
              setReset={setReset}
            />
            {/* <JobsFilter
              label="Job Niche"
              setValues={setJobNiche}
              value={jobNicheList}
              reset={reset}
              setReset={setReset}
            /> */}
            <JobsFilter
              label="Job Niche"
              setValues={setJobCategory}
              value={jobCategoryLists}
              reset={reset}
              setReset={setReset}
              defaultValue={jobCategory}
            />
            <button
              className="btn btnorange"
              onClick={() => resetFilterHandler()}>
              <AiFillPlusCircle /> Reset
            </button>
          </div>
        ) : null}

        {/* --------------table-------------- */}
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="users-table">
              <Table responsive>
                <thead>
                  <tr>
                    <th>{_t("First Name")}</th>
                    <th>{_t("Last Name")}</th>
                    <th>{_t("Email")}</th>
                    <th>{_t("Phone")}</th>
                    <th>{_t("Location")}</th>
                    <th>{_t("Category")}</th>
                    <th>{_t("Niche")}</th>
                    <th>{_t("Language")}</th>
                    <th>{_t("Application Date")}</th>
                    <th>{_t("Status")}</th>
                    <th>{_t("Resume")}</th>
                    <th>{_t("Action")}</th>
                    <th>{_t("Communication")}</th>
                  </tr>
                </thead>
                <tbody>
                  {applicationList?.map((application, index) => (
                    <tr key={index}>
                      <td>
                        <div className="user-details">
                          <div>
                            <p className="user-name">{application?.name}</p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="user-details">
                          <div>
                            <p className="user-name">{application?.lName}</p>
                          </div>
                        </div>
                      </td>

                      <td>{application?.email}</td>
                      <td className="red-text">
                        {application?.mobileNo
                          ? application?.mobileNo
                          : "N/A"}
                      </td>
                      <td className="red-text">
                        <MdLocationPin />{" "}
                        {application?.city && application?.city + ","}{" "}
                        {application.state}
                      </td>
                      <td className="red-text">{application?.category}</td>
                      <td className="red-text">
                        {application?.jobNiche?.join(",")}
                      </td>
                      <td className="red-text">
                        {application?.jobId?.jobLanguage}
                      </td>
                      <td className="red-text">
                        {formatDateWithoutTime(application?.createdAt)}
                      </td>
                      <td className="red-text">{application?.status}</td>
                      <td className="red-text">
                        {application?.resume ? (
                          <Link
                            target="_blank"
                            to={`${config.server.imageBaseUrl}/${application?.resume}`}>
                            <IoDocumentAttach color="black" /> {_t("Resume")}
                          </Link>
                        ) : (
                          "N/A"
                        )}
                      </td>
                      <td>
                        <Link to={`/applicant/${application?._id}`}>
                          <td className="red-text">
                            <button>
                              <CgProfile className="ml-2" />
                              {_t("View Profile")}
                            </button>
                          </td>
                        </Link>
                      </td>
                      <td>
                        <Link
                          to={`/applicant/mails/${application?.email}?name=${application?.name}`}>
                          <td className="red-text">
                            <button>
                              <MdOutlineMarkEmailUnread className="ml-2" />{" "}
                              {_t("Communications")}
                            </button>
                          </td>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <Paginate
                total={totalDocs}
                onChange={handleChangePage}
                page={page}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Applications;
