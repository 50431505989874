import React, { useEffect, useRef } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Joi from "joi";
import config from "../../../config";
import { Link } from "react-router-dom";
import useTranslator from "../../../hooks/useTranslator";
import { IoDocumentAttach } from "react-icons/io5";
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import useStateAndCity from "../../../data-fetch/useStateAndCity";

// Define Joi validation schema
const schema = Joi.object({
  firstName: Joi.string().required().label("First Name"),
  lastName: Joi.string().label("Last Name").allow(""),
  email: Joi.string().email({ tlds: false }).required().label("Email"),
  mobileNo: Joi.string().min(10).max(15).label("Mobile Number").required(),
  state: Joi.string().label("State").required(""),
  city: Joi.string().label("City").required(""),
  zipcode: Joi.string().label("Zipcode").required(""),
});

// Form component
const BasicInfo = ({ data, onUpdate, isNewRecord = true }) => {

  const formikRef = useRef(null);
  const { _t } = useTranslator();

  const {
    fetchCities,
    stateList,
    cityList,
  } = useStateAndCity();


  const handleState = (e) => {
    const selectedState = e.target.value;
    // Access Formik instance methods via ref
    if (formikRef.current) {
      const formikInstance = formikRef.current;
      formikInstance.handleChange(e);
      formikInstance.setFieldValue("city", "");
    }
    const filterState = stateList.find((st) => st.name === selectedState);
    if (filterState) {
      fetchCities(filterState.isoCode);
    }
  };

  const initialValues = {
    firstName: data?.firstName ?? "",
    lastName: data?.lastName ?? "",
    email: data?.email ?? "",
    mobileNo: data?.mobileNo ?? "",
    state: data?.state ?? "",
    city: data?.city ?? "",
    zipcode: data?.zipcode ?? "",
  };

  console.log(initialValues);

  useEffect(() => {
    if (initialValues.state && stateList) {
      const filterState = stateList.find((st) => st.name === initialValues.state);
      if (filterState) {
        fetchCities(filterState.isoCode);
      }
    }
  }, [stateList])

  // Handle form submission
  const onSubmit = async (values) => {
    try {
      const { error } = schema.validate(values, { abortEarly: false });
      if (error) {
        throw new Error(error.details.map((err) => err.message).join(", "));
      }
      onUpdate(values, true);
    } catch (err) {
      console.error("Validation failed:", err.message);
    }
  };

  // Function to validate form data using Joi
  const validateForm = (values) => {
    const { error } = schema.validate(values, { abortEarly: false });
    onUpdate(values);
    if (error) {
      const errors = {};
      error.details.forEach((err) => {
        errors[err.path[0]] = err.message;
      });
      return errors;
    }
    return {};
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={validateForm}
      onSubmit={onSubmit}
      innerRef={formikRef}
    >
      {({ values, handleChange, handleBlur, handleSubmit }) => (
        <Form onSubmit={handleSubmit} className="candidate-form accountform">
          <h4 className="mb-5">{_t("Please fill in your information below")}</h4>

          <div className="row">

            {
              isNewRecord ? <>


                <div className="col-md-6">
                  <div class="formninputbar">
                    <label htmlFor="firstName">{_t("First Name")}</label>
                    <Field
                      class="form-control"
                      type="text"
                      id="firstName"
                      name="firstName"
                      value={values.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={_t("Enter First Name")}
                    />
                    <ErrorMessage name="firstName" component="div" style={{ color: 'red' }} />
                  </div>

                </div>

                <div className="col-md-6">

                  <div class="formninputbar">
                    <label htmlFor="lastName">{_t("Last Name")}</label>
                    <Field
                      class="form-control"
                      type="text"
                      id="lastName"
                      name="lastName"
                      value={values.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={_t("Enter Last Name")}
                    />
                    <ErrorMessage name="lastName" component="div" style={{ color: 'red' }} />
                  </div>

                </div>

                <div className="col-md-12">

                  <div class="formninputbar">
                    <label htmlFor="email">{_t("Email")}</label>
                    <Field
                      class="form-control"
                      type="email"
                      id="email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={_t("Enter Email")}
                    />
                    <ErrorMessage name="email" component="div" style={{ color: 'red' }} />
                  </div>

                </div>

              </> : <>
                <ul className="quick-apply-field-info">
                  <li>{_t("Name")}: {values?.firstName} {values?.lastName}</li>
                  <li>{_t("Email")}: {values?.email}</li>
                </ul>
              </>
            }

            <div className="col-md-12">

              <div class="formninputbar">
                <label htmlFor="mobileNo">{_t("Mobile No")}</label>
                <Field
                  class="form-control"
                  type="text"
                  id="mobileNo"
                  name="mobileNo"
                  value={values.mobileNo}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={_t("Enter Mobile no.")}
                />
                <ErrorMessage name="mobileNo" component="div" style={{ color: 'red' }} />
              </div>

            </div>

            <div className="col-md-4">

              <div class="formninputbar">
                <label htmlFor="state">{_t("State")}</label>
                <Field
                  class="form-control"
                  type="text"
                  id="state"
                  name="state"
                  value={values.state}
                  onChange={handleState}
                  onBlur={handleBlur}
                  as="select"
                >
                  <option value={""}>{_t("Select State")}</option>
                  {
                    stateList && stateList.map((state) => {
                      return <>
                        <option value={state.name}>{state.name}</option>
                      </>
                    })
                  }
                </Field>
                <ErrorMessage name="state" component="div" style={{ color: 'red' }} />
              </div>

            </div>
            <div className="col-md-4">

              <div class="formninputbar">
                <label htmlFor="city">{_t("City")}</label>
                <Field
                  class="form-control"
                  type="text"
                  id="city"
                  name="city"
                  value={values.city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  as="select"
                >
                  <option value={""}>{_t("Select City")}</option>
                  {
                    cityList && cityList.map((city) => {
                      return <>
                        <option value={city.name}>{city.name}</option>
                      </>
                    })
                  }
                </Field>
                <ErrorMessage name="city" component="div" style={{ color: 'red' }} />
              </div>

            </div>
            <div className="col-md-4">

              <div class="formninputbar">
                <label htmlFor="zipcode">{_t("Zipcode")}</label>
                <Field
                  class="form-control"
                  type="text"
                  id="zipcode"
                  name="zipcode"
                  value={values.zipcode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={_t("Enter Zipcode")}
                />
                <ErrorMessage name="zipcode" component="div" style={{ color: 'red' }} />
              </div>

            </div>

          </div>

          <div className="row">
            <div className="col-md-12">
              <div class="formninputbar block text-center">
                <button type="submit" className="btn btnorange btn btn-primary">{_t("Next")}</button>
              </div>
            </div>
          </div>

        </Form>
      )}
    </Formik>
  );
};

export default BasicInfo;
