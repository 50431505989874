const config = {
  server: {
    // url: "http://localhost:7007/api",
    // imageBaseUrl: "http://localhost:7007/uploads",

    imageBaseUrl: process.env.REACT_APP_IMAGE_BASE_URL,
    url: process.env.REACT_APP_API_URL,
  },
  google: {
    GOOGLE_SITE_KEY: "6Lfub7IqAAAAAPuH1O7o7J2Ozh4VQhKxOaNF9_cr",
    GOOGLE_SITE_SECRET: "6Lfub7IqAAAAANmoaljG7HjVa2UNetcc8HieC7Ek",
  },
  tinyMce: "8kcye3p0frd3ue0h97oy9l74kb2yb100g1kpj0qblmk7hcr3",
};


export default config;

export const GOOGLE_SITE_KEY = config.google.GOOGLE_SITE_KEY;
export const GOOGLE_SITE_SECRET = config.google.GOOGLE_SITE_SECRET;
export const EDITOR_API_KEY = config.tinyMce;
export const imageBaseUrl = config.server.imageBaseUrl;
export const blogCoverImageUrl = `${config.server.imageBaseUrl}`;
export const blogCoverImageDoc = `${config.server.imageBaseUrl}`;
