import React, { useEffect, useRef } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Joi from "joi";
import useTranslator from "../../../hooks/useTranslator";

// Define Joi validation schema
const schema = Joi.object({
  numberOfTechnicians: Joi.number().label("Number of Technicians").required(),
});

// Form component
const TechniciansCount = ({ data, onUpdate, onBack }) => {

  const formikRef = useRef(null);
  const { _t } = useTranslator();

  const initialValues = {
    numberOfTechnicians: data?.numberOfTechnicians ?? null,
  };

  // Handle form submission
  const onSubmit = async (values) => {
    try {
      const { error } = schema.validate(values, { abortEarly: false });
      if (error) {
        throw new Error(error.details.map((err) => err.message).join(", "));
      }
      onUpdate(values, true);
    } catch (err) {
      console.error("Validation failed:", err.message);
    }
  };

  // Function to validate form data using Joi
  const validateForm = (values) => {
    const { error } = schema.validate(values, { abortEarly: false });
    onUpdate(values);
    if (error) {
      const errors = {};
      error.details.forEach((err) => {
        errors[err.path[0]] = err.message;
      });
      return errors;
    }
    return {};
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={validateForm}
      onSubmit={onSubmit}
      innerRef={formikRef}
    >
      {({ values, handleChange, handleBlur, handleSubmit }) => (
        <Form onSubmit={handleSubmit} className="candidate-form accountform">
          <h4 className="mb-5">{_t("How many technicians do you have ?")}</h4>

          <div className="row">


            <div className="col-md-12">

              <div class="formninputbar">
                <Field
                  class="form-control"
                  type="text"
                  id="numberOfTechnicians"
                  name="numberOfTechnicians"
                  value={values.numberOfTechnicians}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={_t("No. of Technicians Eg. 1, 5, 10")}
                />
                <ErrorMessage name="numberOfTechnicians" component="div" style={{ color: 'red' }} />
              </div>

            </div>

          </div>

          <div className="row">
            <div className="col-md-12">
              <div class="formninputbar block text-center">
                <button type="button" onClick={onBack} className="btn btnwhite me-3">{_t("Back")}</button>
                <button type="submit" className="btn btnorange btn btn-primary">{_t("Next")}</button>
              </div>
            </div>
          </div>

        </Form>
      )}
    </Formik>
  );
};

export default TechniciansCount;
