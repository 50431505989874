import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useTranslator from '../../hooks/useTranslator';
import { getCandidateDetails } from '../../api-services/jobs';
import { Helmet } from 'react-helmet';
import toast from "react-hot-toast";
import Loader from '../../Components/Loader';
import CandidateApplyForm from './CandidateApplyForm';

const CandidateQualificationContinue = () => {

    const { id } = useParams();
    const { _t } = useTranslator();

    const [candidateDetails, setCandidateDetails] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        getCandidateDetails({ candidateId: id })
            .then((response) => {
                if (response) {
                    setCandidateDetails(response?.data?.candidate);
                }
            })
            .catch((error) => {
                navigate("/not-found");
            });
    }, [id]);

    const handleApplied = (fields) => {
        toast.success(_t("Thanks you for details, we will get back to you."));
        navigate("/");
    }

    return (
        <>
            {
                candidateDetails === null ? <>
                    <Helmet>
                        <meta name="robots" content="noindex, nofollow" />
                    </Helmet>
                </> : <>
                    <Helmet>
                        <title>Candidate: #{candidateDetails?._id ?? ""}</title>
                        <meta name="robots" content="noindex, nofollow" />
                    </Helmet>
                </>
            }

            <div className='JobDetail-block application-continue-block'>
                {
                    (candidateDetails === null) ? <>
                        <div className="my-5 empty-box">
                            <Loader />
                        </div>
                    </> : <>
                        <section className="applicationcontinue-section">
                            <div className="container">
                                <div className="quick-block" >
                                    <div className="primary-title">
                                        <h4>{_t("Qualification Review")}</h4>
                                        <h6><small>Reference ID: {candidateDetails?._id}</small></h6>
                                        <h5 className="px-5">{_t("thanksForApplyingCompleteForm")}</h5>
                                        <div className="bar-line" id="formapply"></div>
                                    </div>
                                    <CandidateApplyForm isEdit={true} candidateDetails={candidateDetails} onApplied={handleApplied} />
                                </div>
                            </div>
                        </section>
                    </>
                }
            </div >
        </>
    );

}

export default CandidateQualificationContinue;
