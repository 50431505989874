import React, { useEffect, useState } from "react";
import { Form, InputGroup, Table } from "react-bootstrap";
import { AiFillPlusCircle, AiOutlineSearch } from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import { LuSettings2 } from "react-icons/lu";
import { MdFilterList, MdOutlineMarkEmailUnread, MdLocationPin } from "react-icons/md";
import BackButton from "../../../Components/BackToPrev";
import Paginate from "../../../Components/Paginate";

import {
  exportSheetDataApplication,
} from "../../../api-services/application";

import debounce from "lodash/debounce";
import { IoDocumentAttach } from "react-icons/io5";
import { Link, useSearchParams } from "react-router-dom";
import JobsFilter from "../../../Components/Filter/JobsFilter";
import config from "../../../config";
import {
  ExperienceConstantArray,
  SelectTrueAndFalse,
  formatDateWithoutTime,
  uniqueValueArray,
  urlEncode,
} from "../../../utils/helpers";

import Loader from "../../../Components/Loader";
import { imageBaseUrl } from "../../../api-services/auth";
import useTranslator from "../../../hooks/useTranslator";
import { useFetchJobCategory } from "../../../data-fetch/useJobCategory";
import { getCandidates } from "../../../api-services/candidate";
import { useCandidateValues } from "../../../data-fetch/useCandidateValues";

const CandidatesList = () => {

  let [searchParams, setSearchParams] = useSearchParams();

  const { _t } = useTranslator();

  const [loading, setLoading] = useState(false);

  const [applicationList, setApplicationList] = useState([]);
  const [totalDocs, setTotalDocs] = useState("");

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const [searchText, setSearchText] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [experience, setExperience] = useState("");
  const [certified, setCertified] = useState("");
  const [niche, setNiche] = useState(searchParams.get("jobCategory"));

  const [showArchived, setShowArchived] = useState(false);
  const [reset, setReset] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  const { jobCategories } = useFetchJobCategory();
  const {states, cities, experiences} = useCandidateValues();

  const debouncedSearch = debounce((value) => {
    setLoading(true);
    getCandidates({
      searchText,
      page,
      limit,
      state,
      city,
      experience,
      certified,
      niche,
      showArchived
    })
      .then((data) => {
        if (data.status === "success") {
          setApplicationList(data.data.docs);
          setTotalDocs(data.data.totalDocs);
        }
      })
      .catch((error) => {
        console.log("error while fetch jobs admin", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, 500); // Adjust the debounce time as needed

  useEffect(() => {
    debouncedSearch(searchText);
  }, [
    searchText,
    page,
    limit,
    state,
    city,
    experience,
    certified,
    niche,
    showArchived
  ]);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  function resetFilterHandler() {
    setSearchText("");
    setState("");
    setCity("");
    setExperience("");
    setCertified("");
    setNiche("");
    setReset(true);
    debouncedSearch();
  }

  function exportSheetData() {
    exportSheetDataApplication()
      .then((res) => {
        const fileUrl = `${imageBaseUrl}/exportedData.xlsx`;
        // Create a temporary anchor element
        const a = document.createElement("a");
        a.href = fileUrl;
        a.target = "_blank";
        a.download = "exportedData.xlsx";
        // Trigger a click event to start the download
        a.click();
      })
      .catch((err) => {
        console.log("err while ", err);
      });
  }

  const filterHandler = () => {
    setShowFilter((prev) => !prev);
  };

  const nicheList = [];
  for (const jobCategory of jobCategories) {
    nicheList.push({
      name: jobCategory.value,
      value: jobCategory.value
    });
  }

  const stateList = [];
  for (const stateItem of states) {
    stateList.push({
      name: stateItem,
      value: stateItem
    });
  }

  const cityList = [];
  for (const cityItem of cities) {
    cityList.push({
      name: cityItem,
      value: cityItem
    });
  }

  const experienceList = [];
  for (const experienceItem of experiences) {
    experienceList.push({
      name: experienceItem,
      value: experienceItem
    });
  }

  const viewArchived = () => {
    setShowArchived(!showArchived);
  }

  return (
    <>
      <div className="users-page">
        <div className="top-header">
          <div className="page-name title-with-backbtn mb-0">
            <BackButton />
            <h4>Candidates</h4>
          </div>
          <div className="import-export">
            {
              !showArchived ? <>
                <Link className="btn btnorange" to="/candidates/new">
                  <AiFillPlusCircle /> Add new Candidate
                </Link>
                <button className="btn btnorange" onClick={exportSheetData}>
                  Export
                </button>
              </> : null
            }
          </div>
        </div>
        {/* ----------searchbox---------- */}
        <div className="serachbar-box">
          <div className="searchbar">
            <input onChange={(e) => handleSearch(e)} placeholder="Search Candidate" class="theme-form-control" value={searchText} />
          </div>
        </div>
        {/* ----------------advance filter ----------------------- */}
        <div className="mb-5">
          <button className="btn btn-sm btnorange me-3" onClick={filterHandler}>
            <MdFilterList /> Advance Filter
          </button>
          {
            showArchived ? <>
              <button className="btn btn-sm btnorange" onClick={viewArchived}>
                View Un-archived
              </button>
            </> : <>
              <button className="btn btn-sm btnorange" onClick={viewArchived}>
                View Archived
              </button>
            </>
          }
        </div>

        {showFilter ? (
          <div className="top-header filterbaradmin">
            <JobsFilter
              label="State"
              setValues={setState}
              value={stateList}
              reset={reset}
              setReset={setReset}
            />
            <JobsFilter
              label="City"
              setValues={setCity}
              value={cityList}
            />
            <JobsFilter
              label="Experiences"
              setValues={setExperience}
              value={experienceList}
              reset={reset}
              setReset={setReset}
            />
            <JobsFilter
              label="Certified"
              setValues={setCertified}
              value={SelectTrueAndFalse()}
              reset={reset}
              setReset={setReset}
            />
            <JobsFilter
              label="Job Niche"
              setValues={setNiche}
              value={nicheList}
              reset={reset}
              setReset={setReset}
              defaultValue={niche}
            />
            <button
              className="btn btnorange"
              onClick={() => resetFilterHandler()}>
              Reset
            </button>
          </div>
        ) : null}

        {/* --------------table-------------- */}
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="users-table">
              <Table responsive>
                <thead>
                  <tr>
                    <th>{_t("First Name")}</th>
                    <th>{_t("Last Name")}</th>
                    <th>{_t("Email")}</th>
                    <th>{_t("Phone")}</th>
                    <th>{_t("Location")}</th>
                    <th>{_t("Niche")}</th>
                    <th>{_t("Language")}</th>
                    <th>{_t("Experience")}</th>
                    <th>{_t("Certified")}</th>
                    <th>{_t("Registered On")}</th>
                    <th>{_t("Resume")}</th>
                    <th>{_t("Action")}</th>
                    <th>{_t("Communication")}</th>
                  </tr>
                </thead>
                <tbody>
                  {applicationList?.map((application, index) => (
                    <tr key={index}>
                      <td>
                        <div className="user-details">
                          <div>
                            <p className="user-name">{application?.firstName}</p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="user-details">
                          <div>
                            <p className="user-name">{application?.lastName}</p>
                          </div>
                        </div>
                      </td>
                      <td>{application?.email}</td>
                      <td className="red-text">
                        {application?.mobileNo
                          ? application?.mobileNo
                          : "N/A"}
                      </td>
                      <td className="red-text">
                        {application?.city} 
                        {application?.state ? `, ${application?.state}` : ''}
                        {application?.country ? `, ${application?.country}` : ''}
                      </td>
                      <td className="red-text">
                        {application?.jobNiches?.join(",")}
                      </td>
                      <td className="red-text">
                        {application?.languages?.join(",")}
                      </td>
                      <td className="red-text">
                        {application?.experienceYears}
                      </td>
                      <td className="red-text">
                        {application?.isCertified ? "Yes": "No"}
                      </td>
                      <td className="red-text">
                        {formatDateWithoutTime(application?.createdAt)}
                      </td>
                      <td className="red-text">
                        {application?.resume ? (
                          <Link
                            target="_blank"
                            to={`${config.server.imageBaseUrl}/${application?.resume}`}>
                            <IoDocumentAttach color="black" /> {_t("Resume")}
                          </Link>
                        ) : (
                          "N/A"
                        )}
                      </td>
                      <td>
                        <Link to={`/candidates/${application?._id}`}>
                          <td className="red-text">
                            <button>
                              <CgProfile className="ml-2" />
                              {_t("View Profile")}
                            </button>
                          </td>
                        </Link>
                      </td>
                      <td>
                        <Link
                          to={`/applicant/mails/${application?.email}?name=${urlEncode(application?.firstName + " " + application?.lastName)}`}>
                          <td className="red-text">
                            <button>
                              <MdOutlineMarkEmailUnread className="ml-2" />{" "}
                              {_t("Communications")}
                            </button>
                          </td>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <Paginate
                total={totalDocs}
                onChange={handleChangePage}
                page={page}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default CandidatesList;
