import React, { useEffect, useRef } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Joi from "joi";
import useTranslator from "../../../hooks/useTranslator";
import { getServiceName } from "../../../utils/helpers";

// Define Joi validation schema
const schema = Joi.object({
  vehiclesServiced: Joi.array().items(Joi.string()).min(1).label("Vehicle Serviced").required(),
});

// Form component
const VehiclesServiced = ({ candidateValues, data, onUpdate, onBack }) => {

  const { vehiclesServiced } = candidateValues;

  const formikRef = useRef(null);
  const { _t } = useTranslator();

  const initialValues = {
    vehiclesServiced: data?.vehiclesServiced ?? [],
  };

  // Handle form submission
  const onSubmit = async (values) => {
    try {
      const { error } = schema.validate(values, { abortEarly: false });
      if (error) {
        throw new Error(error.details.map((err) => err.message).join(", "));
      }
      onUpdate(values, true);
    } catch (err) {
      console.error("Validation failed:", err.message);
    }
  };

  // Function to validate form data using Joi
  const validateForm = (values) => {
    onUpdate(values);
    const { error } = schema.validate(values, { abortEarly: false });
    if (error) {
      const errors = {};
      error.details.forEach((err) => {
        errors[err.path[0]] = err.message;
      });
      return errors;
    }
    return {};
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={validateForm}
      onSubmit={onSubmit}
      innerRef={formikRef}
    >
      {({ values, handleChange, handleBlur, handleSubmit }) => (
        <Form onSubmit={handleSubmit} className="candidate-form accountform">
          <h4>{_t("What vehicles or equipment do you service?")}</h4>
          <h6 className="mb-4 text-center">( {_t("Select all that apply")} )</h6>

          <div className="flexbar flexbar-4">

            {
              vehiclesServiced.map((vehicleServiced, index) => {
                return <label className="selectbox" for={`vehiclesServiced-${index}`}>
                  <Field
                    type="checkbox"
                    id={`vehiclesServiced-${index}`}
                    name="vehiclesServiced"
                    value={vehicleServiced}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span className="checkmark"></span>
                  <div className="primebox">
                    <img src={`/images/${getServiceName(vehicleServiced)}`} alt="icon" />
                    <p>{_t(vehicleServiced)}</p>
                  </div>
                </label>
              })
            }

          </div>

          <div className="col-md-12">
            <ErrorMessage name="vehiclesServiced" component="div" style={{ color: 'red' }} />
          </div>

          <div className="row">
            <div className="col-md-12">
              <div class="formninputbar block text-center">
                <button type="button" onClick={onBack} className="btn btnwhite me-3">{_t("Back")}</button>
                <button type="submit" className="btn btnorange">{_t("Next")}</button>
              </div>
            </div>
          </div>

        </Form>
      )}
    </Formik>
  );
};

export default VehiclesServiced;
