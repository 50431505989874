import React from "react";
import { Pagination } from "antd";

const Paginate = ({ total, onChange, page = 1 }) => {
  return (
    <>
      <div className="frontend-pagination">
        <Pagination
          showQuickJumper
          defaultCurrent={page}
          total={total}
          onChange={onChange}
        />
      </div>
    </>
  );
};

export default Paginate;
