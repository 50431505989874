import sendRequest from "../providers/axios";
import Routes from "./routes";

export const getCandidateValues = async (data) => {
  const response = await sendRequest(Routes.candidates.values, "GET", data);
  return response;
};

export const getCandidates = async (data) => {
  const response = await sendRequest(Routes.candidates.list, "GET", data);
  return response;
};

export const getCandidateDetails = async (data) => {
  const response = await sendRequest(Routes.candidates.view, "GET", data);
  return response;
};

export const updateCandidateDetails = async (data) => {
  const response = await sendRequest(Routes.candidates.save, "POST", data);
  return response;
};

export const updateMatchStage = async (data) => {
  const response = await sendRequest(Routes.candidates.updateMatchStage, "POST", data);
  return response;
};

export const getCandidateMatches = async (data) => {
  const response = await sendRequest(Routes.candidates.matches, "GET", data);
  return response;
};