import React, { useState, useEffect } from "react";
import BackButton from "../../../Components/BackToPrev";
import {
  AiOutlineSearch,
  AiOutlineUpload,
  AiFillPlusCircle,
} from "react-icons/ai";
import { InputGroup, Form, Table } from "react-bootstrap";
import Paginate from "../../../Components/Paginate";
import { MdLocationPin } from "react-icons/md";
import { Link } from "react-router-dom";
import { AdminJobsFetch } from "../../../api-services/jobs";
import debounce from "lodash/debounce";
import useTranslator from "../../../hooks/useTranslator";
import {
  createJobCategory,
  deleteJobCategory,
  getAllJobCategory,
  updateJobCategory,
} from "../../../api-services/job-category";
import toast from "react-hot-toast";
import AddModel from "../../../Components/Modals/AddModel";

const JobCategory = () => {
  const [jobList, setJobList] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [totalDocs, setTotalDocs] = useState("");
  const [showModel, setShowModel] = useState(false);
  const [eventType, setEventType] = useState("Create");
  const [category, setCategory] = useState(null);
  const { _t } = useTranslator();

  const debouncedSearch = debounce((value) => {
    getAllJobCategory({ page, limit, searchText: value })
      .then((jobCategoryData) => {
        if (jobCategoryData?.status === "success") {
          setJobList(jobCategoryData?.data?.docs);
          setTotalDocs(jobCategoryData?.data.totalDocs);
        }
      })
      .catch((error) => {
        console.log("Error while fetching jobs: ", error);
      });
  }, 500); // Adjust the debounce time as needed

  useEffect(() => {
    debouncedSearch(searchText);
  }, [searchText, page, limit]);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  const onCreateJobCategory = (data) => {
    createJobCategory(data)
      .then((createData) => {
        toast.success(_t("job category created"));
      })
      .catch((error) => {
        toast.error(_t(error.message));
      });
  };
  const onUpdateCategory = (data) => {
    updateJobCategory(data)
      .then((updateData) => {
        toast.success(_t("job category updated"));
      })
      .catch((error) => {
        toast.error(_t(error.message));
      });
  };

  const onDeletedCategory = (data) => {
    if (window.confirm("Are you sure you want to delete?") === true) {
      deleteJobCategory({ id: data })
        .then((deleteData) => {
          toast.success(_t("job category deleted"));
          debouncedSearch();
        })
        .catch((error) => {
          console.log("error", error);
          toast.error(_t(error?.message));
        });
    }
  };

  const handleShowCreate = () => {
    setEventType("Create");
    setShowModel((prev) => !prev);
  };

  const showEditModel = (data) => {
    setShowModel((prev) => !prev);
    setEventType("Edit");
    setCategory(data);
  };

  const handleHide = () => {
    setShowModel((prev) => !prev);
  };

  const onSubmit = (data) => {
    if (eventType === "Create") {
      onCreateJobCategory(data);
    } else {
      onUpdateCategory({ id: category._id, name: data.name });
    }
    debouncedSearch();
    setShowModel((prev) => !prev);
    handleHide();
  };

  return (
    <>
      <div className="users-page">
        <div className="top-header">
          <div className="page-name title-with-backbtn mb-0">
            <BackButton />
            <h4>Job Niche</h4>
          </div>
          <div className="import-export">
            <button className="btn btnorange" onClick={handleShowCreate}>
              <AiFillPlusCircle /> {_t("Add Job Niche")}
            </button>
            {/* <button>
              <AiOutlineUpload /> Export
            </button> */}
          </div>
        </div>
        {/* ----------searchbox---------- */}
        <div className="serachbar-box">
          <div className="searchbar">
            <InputGroup>
              <InputGroup.Text id="basic-addon1">
                <AiOutlineSearch />
              </InputGroup.Text>
              <Form.Control
                placeholder="Search Job"
                aria-label="Username"
                aria-describedby="basic-addon1"
                onChange={(e) => handleSearch(e)}
                value={searchText}
              />
            </InputGroup>
          </div>
        </div>
        {/* --------------table-------------- */}
        <div className="users-table">
          <Table responsive>
            <thead>
              <tr className="user-name">
                <th>{_t("Name")}</th>
                <th>{_t("Action")}</th>
                {/* <th>{_t("Action")}</th> */}
              </tr>
            </thead>
            <tbody>
              {jobList?.map((job, index) => (
                <tr key={index}>
                  <td>
                    <div className="user-details">
                      <div>
                        <p className="user-name">{_t(job?.name)}</p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div
                      className="user-details"
                      onClick={() => showEditModel(job)}>
                      <p className="user-name">{_t("Edit")}</p>
                    </div>
                  </td>
                  {/* <td>
                    <div
                      className="user-details"
                      onClick={() => onDeletedCategory(job._id)}>
                      <p className="user-name">{_t("Remove")}</p>
                    </div>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <Paginate total={totalDocs} onChange={handleChangePage} />
        </div>
      </div>

      {showModel ? (
        <AddModel
          show={showModel}
          hide={handleHide}
          value={category}
          eventType={eventType}
          onSubmit={onSubmit}
        />
      ) : null}
    </>
  );
};

export default JobCategory;
