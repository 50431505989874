import React, { useEffect, useState } from "react";
import { Outlet, redirect, useNavigate } from "react-router-dom";
import Superadminsidebar from "./Superadminsidebar";
import { Navbar, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "../../../api-services/auth";
import { login, logout } from "../../../Redux/Slices/authSlice";

const SuperAdminLayout = () => {
  const { status: isLogin } = useSelector((state) => state.authSlice);
  console.log("🚀 ~ SuperAdminLayout ~ isLogin:", isLogin);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    getCurrentUser()
      .then((data) => {
        setLoading(true);
        console.log("🚀 ~ .then ~ data:", data);
        if (data?.status === "success") {
          setLoading(false);
          console.log(data, "data from user login get user");
          dispatch(login(data.data));
        }
      })
      .catch((e) => {
        console.log("error in get login info", e)
      })
      .finally(() => setLoading(false));
  }, [dispatch]);

  if (loading) <div>Loading.....</div>;

  if (isLogin)
    return (
      <>
        <div className="Super-admin-layout DashboardPageLayout">
          <Superadminsidebar />
          <div className="mainwrapper">
            <Navbar expand="false" className="bg-body-tertiary navmob">
              <Container fluid>
                <Navbar.Brand href="#home">
                  <img
                    src="images/logo.webp"
                    width="auto"
                    height="auto"
                    className="d-inline-block align-top"
                    alt="logo"
                  />
                </Navbar.Brand>
                <Navbar.Toggle
                  aria-controls={`offcanvasNavbar-expand-false`}
                  onClick={() =>
                    document.body.classList.toggle("sidebaractive")
                  }
                />
              </Container>
            </Navbar>
            <div className="padding-admin">
              <Outlet />
            </div>
          </div>
        </div>
      </>
    );

  console.log("working second");
  redirect("/login");
};

export default SuperAdminLayout;
