import React, { useRef } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Joi from "joi";
import useTranslator from "../../../hooks/useTranslator";

// Define Joi validation schema
const schema = Joi.object({
  servicesProvided: Joi.array().items(Joi.string()).min(1).label("Services Provided").required(),
});

// Form component
const ServicesInfo = ({ candidateValues, data, onUpdate, onBack }) => {

  const { servicesProvided } = candidateValues;

  const formikRef = useRef(null);
  const { _t } = useTranslator();

  const initialValues = {
    servicesProvided: data?.servicesProvided ?? [],
  };

  // Handle form submission
  const onSubmit = async (values) => {
    try {
      const { error } = schema.validate(values, { abortEarly: false });
      if (error) {
        throw new Error(error.details.map((err) => err.message).join(", "));
      }
      onUpdate(values, true);
    } catch (err) {
      console.error("Validation failed:", err.message);
    }
  };

  // Function to validate form data using Joi
  const validateForm = (values) => {
    onUpdate(values);
    const { error } = schema.validate(values, { abortEarly: false });
    if (error) {
      const errors = {};
      error.details.forEach((err) => {
        errors[err.path[0]] = err.message;
      });
      return errors;
    }
    return {};
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={validateForm}
      onSubmit={onSubmit}
      innerRef={formikRef}
    >
      {({ values, handleChange, handleBlur, handleSubmit }) => (
        <Form onSubmit={handleSubmit} className="candidate-form accountform">
          <h4>{_t("What Services Are You interested in Providing?")}</h4>
          <h6 className="mb-4 text-center">( {_t("Select all that apply")} )</h6>

          <div className="row">

            {
              servicesProvided.map((servicesProvide, index) => {
                return <div className="col-md-12">
                  <label className="selectbox w-100 h-auto" for={`servicesProvided-${index}`}>
                    <Field
                      type="checkbox"
                      id={`servicesProvided-${index}`}
                      name="servicesProvided"
                      value={servicesProvide}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <span className="checkmark"></span>
                    <div className="primebox">
                      <p>{_t(servicesProvide)}</p>
                    </div>
                  </label>
                </div>
              })
            }

            <div className="col-md-12">
              <ErrorMessage name="servicesProvided" component="div" style={{ color: 'red' }} />
            </div>

          </div>

          <div className="row">
            <div className="col-md-12">
              <div class="formninputbar block text-center">
                <button type="button" onClick={onBack} className="btn btnwhite me-3">{_t("Back")}</button>
                <button type="submit" className="btn btnorange">{_t("Next")}</button>
              </div>
            </div>
          </div>

        </Form>
      )}
    </Formik>
  );
};

export default ServicesInfo;
