import React, { Fragment, useEffect, useState } from "react";

import BackButton from "../../../Components/BackToPrev";

import { categoriesApplication } from "../../../api-services/dashboard";
import DashboardCategoryTable from "../../../Components/DashboardCategoryTable";
import Loader from "../../../Components/Loader";
import DashboardCard from "../../../Components/DashboardCard";
import { AiOutlineArrowRight } from "react-icons/ai";
import { Link } from "react-router-dom";
import { urlEncode } from "../../../utils/helpers";

const DashboardPage = () => {
  const [categoriesData, setCategiresData] = useState([]);
  const [categoriesApplicationInfo, setCategoriesApplication] = useState([]);
  const [jobCategoryData, setJobCategoryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalApplicationsCount, setTotalApplicationCount] = useState(null);

  const [applicationsStats, setApplicationsStats] = useState([]);

  const [totalManatalApplicationsCound, setManatalTotalApplicationCount] = useState(null);
  const [totalOtherApplicationsCound, setOtherTotalApplicationCount] = useState(null);

  const [totalCandidates, setTotalCandidates] = useState(null);
  const [totalManatalCandidates, setTotalManatalCandidates] = useState(null);
  const [totalOtherCandidates, setTotalOtherCandidates] = useState(null);

  const [totalLeadCount, setTotalLeadCount] = useState(null);
  const [totalContactCount, setContactCount] = useState(null);
  const [totalShortApplicationCount, setShortApplicationCount] = useState(null);

  const [totalJobs, setTotalJobs] = useState(null);
  const [totalActiveJobs, setTotalActiveJobs] = useState(null);
  const [totalExpiredJobs, setTotalExpiredJobs] = useState(null);

  const [englishMechanicOpenedJobs, setEnglishMechanicOpenedJobs] = useState(null);
  const [spanishMechanicOpenedJobs, setSpanishMechanicOpenedJobs] = useState(null);
  const [englishShopOwnerOpenedJobs, setEnglishShopOwnerOpenedJobs] = useState(null);
  const [spanishShopOwnerOpenedJobs, setSpanishShopOwnerOpenedJobs] = useState(null);

  const [englishMechanicExpiredJobs, setEnglishMechanicExpiredJobs] = useState(null);
  const [spanishMechanicExpiredJobs, setSpanishMechanicExpiredJobs] = useState(null);
  const [englishShopOwnerExpiredJobs, setEnglishShopOwnerExpiredJobs] = useState(null);
  const [spanishShopOwnerExpiredJobs, setSpanishShopOwnerExpiredJobs] = useState(null);

  useEffect(() => {
    setLoading(true);
    categoriesApplication()
      .then((resData) => {
        if (resData.status === "success") {
          setLoading(false);
          // setCategiresData(resData.data.applicationsData);
          setTotalApplicationCount(resData.data.totalApplication);
          setTotalLeadCount(resData.data.totalLeads);
          setContactCount(resData.data.totalContactSubmission);
          setShortApplicationCount(resData.data.totalShortApplication);

          setManatalTotalApplicationCount(resData.data.totalManatalApplication);
          setOtherTotalApplicationCount(resData.data.totalOtherApplication);

          setTotalJobs(resData.data.totalJobs);
          setTotalActiveJobs(resData.data.activeJobs);
          setTotalExpiredJobs(resData.data.expiredJobs);

          setJobCategoryData(resData.data.jobCategoryData);

          setTotalCandidates(resData.data.totalCandidates);
          setTotalManatalCandidates(resData.data.totalManatalCandidates);
          setTotalOtherCandidates(resData.data.totalOtherCandidates);

          setApplicationsStats(resData?.data?.applicationsStats ?? []);

          setEnglishMechanicOpenedJobs(resData.data.englishMechanicOpenedJobs);
          setSpanishMechanicOpenedJobs(resData.data.spanishMechanicOpenedJobs);
          setEnglishShopOwnerOpenedJobs(resData.data.englishShopOwnerOpenedJobs);
          setSpanishShopOwnerOpenedJobs(resData.data.spanishShopOwnerOpenedJobs);

          setEnglishMechanicExpiredJobs(resData.data.englishMechanicExpiredJobs);
          setSpanishMechanicExpiredJobs(resData.data.spanishMechanicExpiredJobs);
          setEnglishShopOwnerExpiredJobs(resData.data.englishShopOwnerExpiredJobs);
          setSpanishShopOwnerExpiredJobs(resData.data.spanishShopOwnerExpiredJobs);
        }
      })
      .catch((error) => console.log(error, "Error"))
      .finally(() => setLoading(false));
  }, []);

  const handleTableData = (data) => {
    setCategoriesApplication(data);
  };

  // console.log("categoriesData", categoriesData);

  return (
    <>
      <div className="overview-page">
        <div className="top-header">
          <div className="page-name">
            <BackButton />
            <h4>Overview</h4>
          </div>
          {/* <div className="import-export">
            <button>
              <AiOutlineDownload /> Import
            </button>
            <button>
              <AiOutlineUpload /> Export
            </button>
          </div> */}
        </div>
        {/* -------main page starts------- */}
        {loading ? (
          <Loader />
        ) : (
          <div className="main-page">

            <div className="top-cards">

              <DashboardCard
                totalApplicationsCount={totalCandidates}
                label="Total Candidates"
              />
              <DashboardCard
                totalApplicationsCount={totalManatalCandidates}
                label="Manatal Candidates"
              />
              <DashboardCard
                totalApplicationsCount={totalOtherCandidates}
                label="Other Candidates"
              />

            </div>

            <div className="top-cards">
              <DashboardCard
                totalApplicationsCount={totalApplicationsCount}
                label="Total Application"
              />
              <DashboardCard
                totalApplicationsCount={totalManatalApplicationsCound}
                label="Manatal Application"
              />
              <DashboardCard
                totalApplicationsCount={totalOtherApplicationsCound}
                label="Other Application"
              />

            </div>

            <div className="top-cards">

              <DashboardCard
                totalApplicationsCount={totalContactCount}
                label="Total Contact"
              />
              <DashboardCard
                totalApplicationsCount={totalLeadCount}
                label="Total Leads"
              />
              <DashboardCard
                totalApplicationsCount={totalShortApplicationCount}
                label="Total Short Application"
              />
            </div>
            <div className="top-cards">
              <DashboardCard
                totalApplicationsCount={totalJobs}
                label="Total Jobs"
              />
              <DashboardCard
                totalApplicationsCount={totalActiveJobs}
                label="Active Jobs"
              />
              <DashboardCard
                totalApplicationsCount={totalExpiredJobs}
                label="Expired Jobs"
              />
            </div>

            {
              applicationsStats.length > 0 ? <>
                <h2 className="mt-5">Applications Stats</h2>

                <div className="top-cards" style={{ gridTemplateColumns: '1fr 1fr 1fr' }}>
                  {applicationsStats?.map((stat, index) => {
                    return (
                      <DashboardCard
                        key={stat?._id ?? index}
                        totalApplicationsCount={stat?.count ?? 0}
                        label={stat?._id}
                      />
                    );
                  })}
                </div>

              </> : <>
              </>
            }

            <h2 className="mt-5">Mechanic Jobs</h2>
            <div className="top-cards" style={{ gridTemplateColumns: '1fr 1fr 1fr 1fr' }}>
              <DashboardCard
                totalApplicationsCount={englishMechanicOpenedJobs}
                label="English Active Jobs"
              />
              <DashboardCard
                totalApplicationsCount={englishMechanicExpiredJobs}
                label="English Expired Jobs"
              />
              <DashboardCard
                totalApplicationsCount={spanishMechanicOpenedJobs}
                label="Spanish Active Jobs"
              />
              <DashboardCard
                totalApplicationsCount={spanishMechanicExpiredJobs}
                label="Spanish Expired Jobs"
              />
            </div>
            <h2 className="mt-5">Shop Owner Jobs</h2>
            <div className="top-cards" style={{ gridTemplateColumns: '1fr 1fr 1fr 1fr' }}>
              <DashboardCard
                totalApplicationsCount={englishShopOwnerOpenedJobs}
                label="English Active Jobs"
              />
              <DashboardCard
                totalApplicationsCount={englishShopOwnerExpiredJobs}
                label="English Expired Jobs"
              />
              <DashboardCard
                totalApplicationsCount={spanishShopOwnerOpenedJobs}
                label="Spanish Active Jobs"
              />
              <DashboardCard
                totalApplicationsCount={spanishShopOwnerExpiredJobs}
                label="Spanish Expired Jobs"
              />
            </div>

            <h2 className="mt-5">Job Niche Stats</h2>
            <div className="top-cards" style={{ gridTemplateColumns: '1fr 1fr' }}>
              {/* {categoriesData?.map((cat, index) => {
                return (
                  <DashboardCard cat={cat} handleTableData={handleTableData} />
                );
              })} */}
              {jobCategoryData?.map((cat, index) => {
                return (
                  <Fragment key={cat?.jobCategoryId ?? index}>
                    <div className="info-card info-card-big">
                      <div className="card-details">
                        <div className="card-content">
                          <small className="heading-text">{cat?.jobCategoryName}</small>
                          <p>
                            <Link to={`/applications?jobCategory=${encodeURI(cat?.jobCategoryName)}`}>
                              See Application{" "}
                              <span>
                                <AiOutlineArrowRight />
                              </span>
                            </Link>
                          </p>
                        </div>
                        <span className="crypto">
                          <h6>
                            Jobs:- {cat?.jobCount ?? 0} <br />
                            Applicants:- {cat?.applicantCount ?? 0}
                          </h6>
                        </span>
                      </div>
                    </div>
                  </Fragment>
                );
              })}
            </div>

            {/* <DashboardCategoryTable data={categoriesApplicationInfo} /> */}
          </div>
        )}
      </div>
    </>
  );
};
export default DashboardPage;
