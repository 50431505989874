import { useEffect, useState } from "react";
import { getCandidateValues } from "../api-services/candidate";

export function useCandidateValues() {

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [experiences, setExperiences] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [defaultLanguages, setDefaultLanguages] = useState([]);
  const [vehiclesServiced, setVehiclesServiced] = useState([]);
  const [servicesProvided, setServicesProvided] = useState([]);
  const [specialServices, setSpecialServices] = useState([]);
  const [applicationStatuses, setApplicationStatuses] = useState([]);

  const [error, setError] = useState("");

  useEffect(() => {
    getCandidateValues()
      .then((response) => {
        setStates(response?.data?.states ?? []);
        setCities(response?.data?.cities ?? []);
        setExperiences(response?.data?.experiences ?? []);
        setLanguages(response?.data?.languages ?? []);
        setDefaultLanguages(response?.data?.defaultLanguages ?? []);
        setVehiclesServiced(response?.data?.vehiclesServiced ?? []);
        setServicesProvided(response?.data?.servicesProvided ?? []);
        setSpecialServices(response?.data?.specialServices ?? []);
        setApplicationStatuses(response?.data?.applicationStatuses ?? []);
      })
      .catch((error) => {
        setError(error?.message);
      });
  }, []);

  return {
    states,
    cities,
    experiences,
    languages,
    defaultLanguages,
    vehiclesServiced,
    servicesProvided,
    specialServices,
    applicationStatuses,
    error,
  };
}
