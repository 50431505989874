import React from "react";
import useTranslator from "../../../hooks/useTranslator";
import { haveValue } from "../../../utils/helpers";

const JobResponsibility = ({ responsibility }) => {
  const { _t } = useTranslator();
  if (responsibility) {
    return (
      <div className="listing-ul" dangerouslySetInnerHTML={{ __html: responsibility ?? ""}}></div>
    );
  }
};

export default JobResponsibility;
