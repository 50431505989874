import React, { useEffect, useState } from "react";
import { getEmailTemplates, saveEmailTemplate } from "../../../api-services/email-template";
import Loader from "../../../Components/Loader";
import { Card, Col, Form, Modal, Row } from "react-bootstrap";
import { MdCreate } from "react-icons/md";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import useTranslator from "../../../hooks/useTranslator";
import EditorBox from "../../../Components/Editor";
import toast from "react-hot-toast";

const EmailTemplates = () => {

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [editModel, setEditModel] = useState({});

  const [content, setContent] = useState("");

  const schema = yup.object().shape({
    name: yup.string().required("Name is required").label('Name'),
    subject: yup.string().required("Subject is required").label('Subject'),
    emailContent: yup.string().required("Email Content is required").label('Email Content'),
  });

  const { _t } = useTranslator();

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  const onSubmit = (data) => {
    setSaving(true);
    saveEmailTemplate({
      ...data,
      _id: editModel._id
    }).then((res) => {
      toast.success("Email template has been saved");
      loadEmailTemplates();
      setSaving(false);
      setShowModal(false);
    }).catch((err) => {
      console.log(err);
      toast.error("Failed to save email template");
      setSaving(false);
    });
  };

  const loadEmailTemplates = () => {
    setLoading(true);
    getEmailTemplates().then((res) => {
      setItems(res?.data ?? []);
      setLoading(false);
    }).catch((err) => {
      console.log(err);
      toast.error("Failed to load email templates");
      setLoading(false);
    });
  }

  useEffect(() => {
    loadEmailTemplates();
  }, [])

  const handleModalHide = () => {
    setShowModal(false);
  }

  const handleEdit = (model) => {
    setEditModel(model);
    setShowModal(true);
    setValue('name', model?.name);
    setValue('subject', model?.subject);
    setContent(model?.emailContent);
  }

  useEffect(() => {
    setValue('emailContent', content);
  }, [content])

  const onError = (errors, e) => console.log(errors, "Error", e);

  return (
    <div className="email-templates">
      {
        loading ? <>
          <Loader />
        </> : <>

          {items.length && (
            <div className="users-table mt-3">
              <Row xs={1} md={1} className="g-4 mb-4">
                {items?.length &&
                  items?.map((item, idx) => (
                    <Col key={idx}>
                      <Card bg="dark">
                        <Card.Body>
                          <Card.Title>{item?.name}</Card.Title>
                          <Card.Text>
                            <p><strong>Subject:</strong> {item?.subject}</p>
                            <div className="btngroup btngrop-leftalign mt-3">
                              <button
                                className="btn btnorange"
                                onClick={() => handleEdit(item)}>
                                <MdCreate />
                                Edit
                              </button>
                            </div>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
              </Row>
            </div>
          )}

        </>
      }

      <Modal size="lg" show={showModal} onHide={handleModalHide} centered className="darkmode-modal">
        <Modal.Header closeButton>
          <Modal.Title>
            Edit Email Template
          </Modal.Title>
        </Modal.Header >
        <Modal.Body>
          <div className="modal-form step-form-container modal-block-bar">
            <div className="block-bar">
              <Form className="mt-0 form" onSubmit={handleSubmit(onSubmit, onError)}>
                <div className="formrow">

                  <div className="dflex-inputbar form-100">
                    <div className="btnlignself">
                      <Form.Label>Name</Form.Label>
                    </div>
                    <Form.Group className="forminput" controlId="name">
                      <Form.Control
                        type="text"
                        placeholder="Enter Name"
                        {...register("name")}
                      />
                      <p className="error">
                        {" "}
                        {errors.name && errors.name.message}
                      </p>
                    </Form.Group>
                  </div>

                  <div className="dflex-inputbar form-100">
                    <div className="btnlignself">
                      <Form.Label>Subject</Form.Label>
                    </div>
                    <Form.Group className="forminput" controlId="subject">
                      <Form.Control
                        type="text"
                        placeholder="Enter Subject"
                        {...register("subject")}
                      />
                      <p className="error">
                        {" "}
                        {errors.subject && errors.subject.message}
                      </p>
                    </Form.Group>
                  </div>

                  <div className="dflex-inputbar form-100">
                    <div className="btnlignself">
                      <Form.Label>Email Content</Form.Label>
                    </div>
                    <EditorBox content={content} setContent={setContent} />
                    <p className="error">
                      {" "}
                      {errors?.emailContent &&
                        `${errors?.emailContent.message}`}
                    </p>
                  </div>

                  {
                    editModel?.tagVariables && editModel.tagVariables.length > 0 ? <>
                      <div className="dflex-inputbar">
                        <p className="mb-2">You can use these variables in email and subject</p>
                        <div className="email-tag-variables mb-3">
                          {
                            editModel.tagVariables.map((variable) => {
                              return <span className="badge badge-primary">{`{{${variable}}}`}</span>
                            })
                          }
                        </div>
                      </div>
                    </> : null
                  }

                  <div className="dflex-inputbar form-100">
                    <div className="btnform">
                      {
                        saving ? <>
                          <Loader />
                        </> : <>
                          <button className="btn btnwhite" type="button" onClick={() => handleModalHide()}>
                            Cancel
                          </button>
                          <button className="btn btnorange" type="submit">
                            Save
                          </button>
                        </>
                      }
                    </div>
                  </div>

                </div>
              </Form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

    </div >
  );
};

export default EmailTemplates;
