import React, { useEffect, useRef } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Joi from "joi";
import useTranslator from "../../../hooks/useTranslator";
import { getServiceName } from "../../../utils/helpers";

// Define Joi validation schema
const schema = Joi.object({
  experienceYears: Joi.string().label("Experience Years").required(),
});

// Form component
const ExperienceYears = ({ isMechanicJob, candidateValues, data, onUpdate, onBack }) => {

  const { experiences } = candidateValues;

  const formikRef = useRef(null);
  const { _t } = useTranslator();

  const initialValues = {
    experienceYears: data?.experienceYears ?? "",
  };

  // Handle form submission
  const onSubmit = async (values) => {
    try {
      const { error } = schema.validate(values, { abortEarly: false });
      if (error) {
        throw new Error(error.details.map((err) => err.message).join(", "));
      }
      onUpdate(values, true);
    } catch (err) {
      console.error("Validation failed:", err.message);
    }
  };

  // Function to validate form data using Joi
  const validateForm = (values) => {
    onUpdate(values);
    const { error } = schema.validate(values, { abortEarly: false });
    if (error) {
      const errors = {};
      error.details.forEach((err) => {
        errors[err.path[0]] = err.message;
      });
      return errors;
    }
    return {};
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={validateForm}
      onSubmit={onSubmit}
      innerRef={formikRef}
    >
      {({ values, handleChange, handleBlur, handleSubmit }) => (
        <Form onSubmit={handleSubmit} className="candidate-form accountform">

          {
            isMechanicJob ? <>
              <h4>{_t("How many years of experience do you have?")}</h4>
            </> : <>
              <h4>{_t("How many years of experience do your technicians have?")}</h4>
            </>
          }

          <div className="row">

            {
              experiences.map((experienceItem, index) => {
                return <div className="col-md-12"><label className="selectbox w-100 h-auto" for={`experienceYears-${index}`}>
                  <Field
                    type="radio"
                    id={`experienceYears-${index}`}
                    name="experienceYears"
                    value={experienceItem}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span className="checkmark"></span>
                  <div className="primebox">
                    <p>{_t(experienceItem)}</p>
                  </div>
                </label>
                </div>
              })
            }

          </div>

          <div className="col-md-12">
            <ErrorMessage name="experienceYears" component="div" style={{ color: 'red' }} />
          </div>

          <div className="row">
            <div className="col-md-12">
              <div class="formninputbar block text-center">
                <button type="button" onClick={onBack} className="btn btnwhite me-3">{_t("Back")}</button>
                <button type="submit" className="btn btnorange">{_t("Next")}</button>
              </div>
            </div>
          </div>

        </Form>
      )}
    </Formik>
  );
};

export default ExperienceYears;
