import sendRequest from "../providers/axios";
import Routes from "./routes";

export const getUserJobs = async (data) => {
  const response = await sendRequest(Routes.job.userListing, "GET", data);
  return response;
};

export const AdminJobsFetch = async (data) => {
  const response = await sendRequest("/jobs/admin", "GET", data);
  return response;
};

export const postJob = async (data) => {
  const response = await sendRequest("jobs", "POST", data);
  return response;
};

export const getUserSingleJob = async (data) => {
  const response = await sendRequest(Routes.job.usrJobs, "GET", data);
  return response;
};
export const getJobListing = async (data) => {
  const response = await sendRequest(Routes.job.jobListing, "GET", data);
  return response;
};
export const editJobInfo = async (data) => {
  const response = await sendRequest(Routes.job.jobEdit, "PUT", data);
  return response;
};
export const deleteJobInfo = async (data) => {
  const response = await sendRequest(Routes.job.jobDeleted, "PUT", data);
  return response;
};
export const rePublishJobInfo = async (data) => {
  const response = await sendRequest(Routes.job.jobRepublish, "PUT", data);
  return response;
};

export const states = async (data) => {
  const response = await sendRequest(Routes.helper.states, "GET", data);
  return response;
};
export const cities = async (data) => {
  console.log("🚀 ~ cities ~ data:", data)
  const response = await sendRequest(Routes.helper.cities, "GET", data);
  return response;
};

export const getApplicationDetails = async (data = {}) => {
  const response = await sendRequest(Routes.job.applicationDetails, "GET", data);
  return response;
};

export const getCandidateDetails = async (data = {}) => {
  const response = await sendRequest(Routes.job.candidateDetails, "GET", data);
  return response;
};

export const saveCandidateLocationDetails = async (data = {}) => {
  const response = await sendRequest(Routes.job.saveCandidateLocationDetails, "POST", data);
  return response;
};

export const saveCandidateQualificationDetails = async (data = {}) => {
  const response = await sendRequest(Routes.job.saveCandidateQualificationDetails, "POST", data);
  return response;
};

export const applyToJob = async (data = {}) => {
  const response = await sendRequest(Routes.job.apply, "POST", data);
  return response;
};

export const getCandidateByEmail = async (data = {}) => {
  const response = await sendRequest(Routes.job.getCandidateByEmail, "POST", data);
  return response;
};

export const uploadResume = async (data = {}) => {
  const response = await sendRequest(
    Routes.job.uploadResume,
    "POST",
    data,
    null,
    'multipart/form-data'
  );
  return response;
};