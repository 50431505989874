import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {
    state: "",
    zipCode: "",
    yearsExperience: "",
    isCertified: "",
    languagesSpoken: [],
    vehiclesService: [],
    servicesProvided: [],
    additionalServices: [],

    resideInUS: "",
    requireWorkVisaSponsorshipForUs: "",
    haveTireRepairEquipment: "",
    haveTowingEquipment: "",
  },
  error: null,
  next: false,
};

const jobApplicationSlice = createSlice({
  name: "jobApplication",
  initialState,
  reducers: {
    saveData: (state, action) => {
      state.data = { ...state.data, ...action.payload };
    },
    clearData: (state, action) => {
      state.data = {};
    },
  },
});

export const { saveData, clearData } = jobApplicationSlice.actions;

export default jobApplicationSlice.reducer;
