import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useTranslator from '../../hooks/useTranslator';
import Loader from '../../Components/Loader';
import toast from 'react-hot-toast';
import { getCandidateByEmail } from '../../api-services/jobs';
import { haveValue } from '../../utils/helpers';

const CandidateQualificationContinueBefore = () => {

    const { _t } = useTranslator();

    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState(null);
    const navigate = useNavigate();

    const handleContinue = () => {
        if (haveValue(email)) {
            setLoading(true);
            getCandidateByEmail({ email }).then((res) => {
                setLoading(false);
                navigate(`/application/qualification/${res?.data?._id}`);
            }).catch((error) => {
                setLoading(false);
                toast.error(_t(error?.message ?? _t("Application details not found")));
            });
        } else {
            toast.error(_t("Please enter email to continue"));
        }
    }

    const handleChange = (e) => {
        setEmail(e?.target?.value ?? "");
    }

    return (
        <>
            <div className='JobDetail-block application-continue-block'>
                <section className="applicationcontinue-section">
                    <div className="container">
                        <div className="quick-block" >
                            <div className="primary-title">
                                <h4>{_t("Qualification Review")}</h4>
                                <h5 className="px-5">{_t("thanksForApplyingCompleteForm")}</h5>
                                <div className="bar-line" id="formapply"></div>
                            </div>
                            <div className="step-form-container step-1">
                                <div className="container" id="formapply-1">
                                    {
                                        loading ? <>
                                            <Loader />
                                        </> : <>

                                            <div className="block-bar">
                                                <div className='w-full block'>
                                                    <div className="formsteps mt-1">
                                                        <h4 className="mb-3">{_t("Please enter your email to continue your job application.")}</h4>
                                                        <div className="formrow form">

                                                            <div className="dflex-inputbar form-100">
                                                                <div className="col-md-12">
                                                                    <div class="formninputbar">
                                                                        <label htmlFor="email">{_t("Email")}</label>
                                                                        <input class="form-control"
                                                                            type="email"
                                                                            id="email"
                                                                            name="email"
                                                                            value={email}
                                                                            onChange={handleChange}
                                                                            placeholder={_t("Enter Email")} />
                                                                    </div>
                                                                </div>

                                                                <div className="btnbar twobtncenter">
                                                                    <button className="btn btnorange" onClick={handleContinue} type="button">
                                                                        {_t("Continue")}
                                                                    </button>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );

}

export default CandidateQualificationContinueBefore;
