import React from "react";
import useTranslator from "../../../hooks/useTranslator";

const JobSummary = ({ qualifications }) => {
  const { _t } = useTranslator();

  return (
    <>
      <div className="spacer-40"></div>
      <h5>{_t("Qualifications")}</h5>

      <div className="listing-ul" dangerouslySetInnerHTML={{ __html: qualifications?.education ?? "" }}></div>

      <div className="spacer-40"></div>

      <h5>{_t("Experience")}</h5>

      <div className="listing-ul" dangerouslySetInnerHTML={{ __html: qualifications?.experience ?? "" }}></div>
    </>
  );
};

export default JobSummary;
