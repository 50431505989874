import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useTranslator from '../../hooks/useTranslator';
import { applyToJob, saveCandidateQualificationDetails } from '../../api-services/jobs';
import toast from "react-hot-toast";
import Loader from '../../Components/Loader';
import BasicInfo from './QualificationSteps/BasicInfo';
import ServicesInfo from './QualificationSteps/ServicesInfo';
import VehiclesServiced from './QualificationSteps/VehiclesServiced';
import { useCandidateValues } from '../../data-fetch/useCandidateValues';
import SpecialServices from './QualificationSteps/SpecialServices';
import ExperienceYears from './QualificationSteps/ExperienceYears';
import CertifiedTechnician from './QualificationSteps/CertifiedTechnician';
import Languages from './QualificationSteps/Languages';
import Resume from './QualificationSteps/Resume';
import { convertToBooleanValue } from '../../utils/helpers';
import CertifiedTechnicians from './QualificationSteps/CertifiedTechnicians';
import TechniciansCount from './QualificationSteps/TechniciansCount';
import Licensed from './QualificationSteps/Licensed';
import IsOEM from './QualificationSteps/IsOEM';

const CandidateApplyForm = ({ candidateDetails, onApplied, isEdit = false, job = null }) => {

    const { _t } = useTranslator();

    const navigate = useNavigate();
    const [renderForm, setRenderForm] = useState(false);

    const candidateValues = useCandidateValues();

    const [formFields, setFormFields] = useState({
        _id: null,

        firstName: "",
        lastName: "",
        email: "",
        mobileNo: "",
        state: "",
        city: "",
        zipcode: "",

        summary: "",
        notes: "",

        vehiclesServiced: [],
        servicesProvided: [],
        specialServices: [],
        languages: [],

        resume: "",
        certificationText: "",

        experienceYears: "",
        isCertified: null,

        businessName: "",
        numberOfTechnicians: null,
        certifiedTechnicians: null,
        isLicensed: null,
        isOem: null,
    });

    useEffect(() => {
        if (candidateDetails?._id) {
            setFormFields({
                ...formFields,
                _id: candidateDetails?._id,
                firstName: candidateDetails?.firstName ?? "",
                lastName: candidateDetails?.lastName ?? "",
                email: candidateDetails?.email ?? "",
                mobileNo: candidateDetails?.mobileNo ?? "",
                gender: candidateDetails?.gender ?? "",
                state: candidateDetails?.state ?? "",
                city: candidateDetails?.city ?? "",
                zipcode: candidateDetails?.zipcode ?? "",

                summary: candidateDetails?.summary ?? "",
                notes: candidateDetails?.notes ?? "",

                vehiclesServiced: candidateDetails?.vehiclesServiced ?? [],
                servicesProvided: candidateDetails?.servicesProvided ?? [],
                specialServices: candidateDetails?.specialServices ?? [],
                languages: candidateDetails?.languages ?? [],

                resume: candidateDetails?.resume ?? null,
                certificationText: candidateDetails?.certificationText ?? "",

                experienceYears: candidateDetails?.experienceYears ?? "",
                isCertified: convertToBooleanValue(candidateDetails?.isCertified),

                businessName: candidateDetails?.businessName ?? "",
                numberOfTechnicians: candidateDetails?.numberOfTechnicians ?? null,
                certifiedTechnicians: convertToBooleanValue(candidateDetails?.certifiedTechnicians),
                isLicensed: convertToBooleanValue(candidateDetails?.isLicensed),
                isOem: convertToBooleanValue(candidateDetails?.isOem),
            });
        }
    }, [candidateDetails]);

    const handleUpdate = (submittedFields, next = false) => {
        setFormFields({
            ...formFields,
            ...submittedFields
        });
        if (next) {
            const newStep = step + 1;
            if (newStep > totalSteps) {
                handleSubmit({
                    ...formFields,
                    ...submittedFields
                });
            } else {
                setStep(newStep)
            };
        }
    }

    const [saving, setSaving] = useState(false);

    const isMechanicJob = job?.category === "Shop Owner" ? false : true;

    const totalSteps = isMechanicJob ? 8 : 9;

    const [step, setStep] = useState(1);
    const [barWidth, setBarWidth] = useState(0);

    useEffect(() => {
        const completedSteps = step - 1;
        if (completedSteps > 0) {
            const percentage = (completedSteps / totalSteps) * 100;
            setBarWidth(percentage);
        } else {
            setBarWidth(0);
        }
    }, [step]);

    const changeStep = (step) => {
        setStep(step);
    }

    const handleBack = () => {
        changeStep(step - 1);
    }

    const handleSubmit = (updatedFields) => {
        setSaving(true);
        if (job != null) {
            updatedFields.jobId = job._id;
            applyToJob(updatedFields)
                .then((data) => {
                    if (data.status === "success") {
                        setSaving(false);
                        onApplied(updatedFields)
                        changeStep(1);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    toast.error(_t("Failed to complete your request, please contact support."));
                    setSaving(false);
                });
        } else {
            saveCandidateQualificationDetails(updatedFields)
                .then((data) => {
                    if (data.status === "success") {
                        setSaving(false);
                        onApplied(updatedFields)
                    }
                })
                .catch((err) => {
                    console.log(err);
                    toast.error(_t("Failed to complete your request, please contact support."));
                    setSaving(false);
                });
        }
    }

    useEffect(() => {
        console.log(formFields);
        if (isEdit) {
            if (formFields?._id) {
                setRenderForm(true);
            } else {
                setRenderForm(false);
            }
        } else {
            setRenderForm(true);
        }
    }, [formFields, isEdit])

    if (!renderForm) {
        return <></>
    }

    return (
        <>
            <div className="step-form-container step-1">
                <div className="container" id="formapply-1">
                    {
                        saving ? <>
                            <Loader />
                        </> : <>

                            <div className="block-bar">
                                <div className="line">
                                    <div className="lineinner" style={{ width: `${barWidth.toFixed(2)}%` }}></div>
                                </div>
                                <div className='w-full block'>
                                    <div className="formsteps formstepsbig">
                                        {
                                            step === 1 ? <>
                                                <BasicInfo isNewRecord={!isEdit} renderForm={renderForm} candidateValues={candidateValues} onBack={handleBack} onUpdate={handleUpdate} data={formFields} />
                                            </> : null
                                        }
                                        {
                                            step === 2 ? <>
                                                <ServicesInfo candidateValues={candidateValues} onBack={handleBack} onUpdate={handleUpdate} data={formFields} />
                                            </> : null
                                        }
                                        {
                                            step === 3 ? <>
                                                <VehiclesServiced candidateValues={candidateValues} onBack={handleBack} onUpdate={handleUpdate} data={formFields} />
                                            </> : null
                                        }
                                        {
                                            step === 4 ? <>
                                                <SpecialServices candidateValues={candidateValues} onBack={handleBack} onUpdate={handleUpdate} data={formFields} />
                                            </> : null
                                        }
                                        {
                                            step === 5 ? <>
                                                <ExperienceYears isMechanicJob={isMechanicJob} candidateValues={candidateValues} onBack={handleBack} onUpdate={handleUpdate} data={formFields} />
                                            </> : null
                                        }
                                        {
                                            step === 6 ? <>
                                                {
                                                    isMechanicJob ? <>
                                                        <CertifiedTechnician candidateValues={candidateValues} onBack={handleBack} onUpdate={handleUpdate} data={formFields} />
                                                    </> : <>
                                                        <TechniciansCount candidateValues={candidateValues} onBack={handleBack} onUpdate={handleUpdate} data={formFields} />
                                                    </>
                                                }
                                            </> : null
                                        }
                                        {
                                            step === 7 ? <>
                                                {
                                                    isMechanicJob ? <>
                                                        <Languages candidateValues={candidateValues} onBack={handleBack} onUpdate={handleUpdate} data={formFields} />
                                                    </> : <>
                                                        <CertifiedTechnicians candidateValues={candidateValues} onBack={handleBack} onUpdate={handleUpdate} data={formFields} />
                                                    </>
                                                }
                                            </> : null
                                        }
                                        {
                                            step === 8 ? <>
                                                {
                                                    isMechanicJob ? <>
                                                        <Resume candidateValues={candidateValues} onBack={handleBack} onUpdate={handleUpdate} data={formFields} />
                                                    </> : <>
                                                        <Licensed candidateValues={candidateValues} onBack={handleBack} onUpdate={handleUpdate} data={formFields} />
                                                    </>
                                                }
                                            </> : null
                                        }
                                        {
                                            step === 9 ? <>
                                                {
                                                    isMechanicJob ? <>
                                                        
                                                    </> : <>
                                                        <IsOEM candidateValues={candidateValues} onBack={handleBack} onUpdate={handleUpdate} data={formFields} />
                                                    </>
                                                }
                                            </> : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div >
        </>

    );

}

export default CandidateApplyForm;
