import React, { useEffect, useState } from "react";
import BackButton from "../../../Components/BackToPrev";
import debounce from "lodash/debounce";
import useTranslator from "../../../hooks/useTranslator";
import { getCandidateDetails, getCandidateMatches } from "../../../api-services/candidate";
import useStateAndCity from "../../../data-fetch/useStateAndCity";
import ApplicationEntries from "./ApplicationEntries";
import { useParams } from "react-router-dom";
import CandidateForm from "./CandidateForm";
import { isEmpty } from "lodash";
import { useCandidateValues } from "../../../data-fetch/useCandidateValues";

const CandidateView = () => {

  const { _t } = useTranslator();

  const { id: candidateId } = useParams();

  const [loading, setLoading] = useState(false);

  const [candidateDetails, setCandidateDetails] = useState({});

  const [applicationList, setApplicationList] = useState([]);
  const [totalDocs, setTotalDocs] = useState("");

  const { applicationStatuses } = useCandidateValues();

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const {
    fetchCities: fetchCities,
    stateList: stateList,
    cityList: cityList,
  } = useStateAndCity();

  const handleState = (val) => {
    // setState(val);
    const filterState = stateList.find((st) => st.name === val);
    if (filterState) {
      fetchCities(filterState.isoCode);
    }
  };

  useEffect(() => {
    if (candidateId) {
      getCandidateDetails({ candidateId }).then((res) => {
        setCandidateDetails(res?.data);
      }).catch(error => {
        console.log(error);
      });
    }
  }, [candidateId]);

  const debouncedSearch = debounce(() => {
    setLoading(true);
    getCandidateMatches({
      page,
      limit,
      candidateId
    })
      .then((data) => {
        if (data.status === "success") {
          setApplicationList(data.data.docs);
          setTotalDocs(data.data.totalDocs);
        }
      })
      .catch((error) => {
        console.log("error while fetch jobs admin", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, 500);

  useEffect(() => {
    debouncedSearch();
  }, [
    page,
    limit,
  ]);

  const handleChangePage = (page) => {
    setPage(page);
  };

  return (
    <>
      <div className="users-page">
        <div className="top-header">
          <div className="page-name title-with-backbtn mb-0">
            <BackButton />
            <h4>Candidate: #{candidateId}</h4>
          </div>
        </div>

        <div className="mt-5">
          {
            !isEmpty(candidateDetails?.firstName) ? <>
              <CandidateForm candidate={candidateDetails} />
            </> : null
          }
        </div>

        <h4 className="my-3">Job Applications</h4>

        <ApplicationEntries
          loading={loading}
          data={applicationList}
          totalDocs={totalDocs}
          page={page}
          applicationStatuses={applicationStatuses}
          handleChangePage={handleChangePage}
          allCandidates={false}
        />

      </div>
    </>
  );
};

export default CandidateView;
